import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Invoice = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Default values in case transferDetails are not available
    const transferDetails = location.state?.transferDetails || {};

    if (!location.state) {
        return (
            <div>
                <p>No transfer details available.</p>
                <button onClick={() => navigate('/')}>Back to Home</button>
            </div>
        );
    }

    return (
        <div className="invoice-container">
            <h2>Invoice</h2>
            <p>Transfer Amount: ₦{transferDetails.amount}</p>
            <p>Recipient: {transferDetails.recipient}</p>
            <p>Transaction Date: {transferDetails.date}</p>
            {/* Add other necessary details */}
            <button onClick={() => navigate('/')}>Back to Home</button>
        </div>
    );
};

export default Invoice;
