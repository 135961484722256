// src/Pages/AirtimeRecharge.jsx

import React, { useState } from "react";
import "./CSS/AirtimeRecharge.css";
import airtimeImage from "../Components/Assets/glo-etisalat-nd-mtn-logo-65021854.gif";
import PaymentConfirmation from "../Components/PaymentConfirmation/PaymentConfirmation"; // Import the new component

const AirtimeRecharge = () => {
    const [network, setNetwork] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [amount, setAmount] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Prepare transaction details to pass to the confirmation component
        const details = {
            network,
            phoneNumber,
            amount,
        };
        setTransactionDetails(details);
        setShowConfirmation(true);
    };

    const handleConfirmation = async (result) => {
        // Handle successful payment here (e.g., show a success message)
        alert(`Airtime recharge successful: ${result.response_description}`);
        // Reset the form after successful transaction
        setNetwork("");
        setPhoneNumber("");
        setAmount("");
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        // Reset the confirmation state
        setShowConfirmation(false);
    };

    return (
        <div className="airtime-page">
            <div className="banner-container">
                <img src={airtimeImage} alt="Banner" className="airtime-image" />
            </div>

            <div className="airtime-recharge">
                <h2 className="airtime-header">Airtime Recharge</h2>

                {showConfirmation ? (
                    <PaymentConfirmation 
                        transactionDetails={transactionDetails}
                        onConfirm={handleConfirmation}
                        onCancel={handleCancel}
                    />
                ) : (
                    <div className="airtime-container">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="network">Network:</label>
                                <select
                                    id="network"
                                    value={network}
                                    onChange={(e) => setNetwork(e.target.value)}
                                    required
                                >
                                    <option value="">Select Network</option>
                                    <option value="mtn">MTN</option>
                                    <option value="glo">Glo</option>
                                    <option value="airtel">Airtel</option>
                                    <option value="etisalat">9mobile</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone-number">Phone Number:</label>
                                <input
                                    type="tel"
                                    id="phone-number"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="amount">Amount (₦):</label>
                                <input
                                    type="number"
                                    id="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit">Recharge Airtime</button>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AirtimeRecharge;
