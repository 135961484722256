// Reviews.js
import React, { useState } from 'react';
import './CSS/Reviews.css';

// Expanded mock data for demonstration
const mockReviews = [
  { id: 1, user: 'John Doe', rating: 5, comment: 'Excellent product!', image: 'url_to_image_1' },
  { id: 2, user: 'Jane Smith', rating: 4, comment: 'Very good, will buy again!', image: 'url_to_image_2' },
  { id: 3, user: 'Alice Johnson', rating: 5, comment: 'Amazing product!' },
  { id: 4, user: 'Bob Brown', rating: 4, comment: 'Very good quality.' },
  { id: 5, user: 'Charlie Davis', rating: 3, comment: "It's okay, not what I expected." },
  { id: 6, user: 'Eve White', rating: 2, comment: 'Did not meet my expectations.' },
  { id: 7, user: 'Mallory Black', rating: 1, comment: 'Terrible, do not buy!' },
];

const Reviews = ({ productId }) => {
  const [reviews, setReviews] = useState(mockReviews);
  const [newReview, setNewReview] = useState({ user: '', rating: 1, comment: '', image: '' });

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedReviews = [
      ...reviews,
      { ...newReview, id: reviews.length + 1, productId }
    ];
    setReviews(updatedReviews);
    setNewReview({ user: '', rating: 1, comment: '', image: '' }); // Reset the form
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} className={`star ${i <= rating ? 'filled' : ''}`}>&#9733;</span> // Unicode star character
      );
    }
    return stars;
  };

  return (
    <div className="reviews-container">
      <h2>Customer Reviews</h2>
      <form onSubmit={handleSubmit} className="review-form">
        <input
          type="text"
          placeholder="Your Name"
          value={newReview.user}
          onChange={(e) => setNewReview({ ...newReview, user: e.target.value })}
          required
        />
        <select
          value={newReview.rating}
          onChange={(e) => setNewReview({ ...newReview, rating: parseInt(e.target.value) })}
          required
        >
          {[1, 2, 3, 4, 5].map((star) => (
            <option key={star} value={star}>{star} Star{star > 1 ? 's' : ''}</option>
          ))}
        </select>
        <textarea
          placeholder="Your Review"
          value={newReview.comment}
          onChange={(e) => setNewReview({ ...newReview, comment: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Image URL (optional)"
          value={newReview.image}
          onChange={(e) => setNewReview({ ...newReview, image: e.target.value })}
        />
        <button type="submit">Submit Review</button>
      </form>
      <div className="reviews-list">
        {reviews.map((review) => (
          <div key={review.id} className="review">
            <h3>{review.user || 'Anonymous'}</h3>
            <div className="rating">{renderStars(review.rating)}</div>
            <p>{review.comment}</p>
            {review.image && <img src={review.image} alt={`${review.user || 'Anonymous'}'s review`} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
