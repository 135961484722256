import React, { useState } from 'react';
import './CreateWallet.css';

const BASE_URL = "http://localhost:4000";

const CreateWallet = () => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [walletCreated, setWalletCreated] = useState(false);
    const [walletInfo, setWalletInfo] = useState(null);
    const [message, setMessage] = useState("");

    const handleCreateWallet = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BASE_URL}/wallet/create`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email, username }),
            });
            const data = await response.json();

            if (response.ok) {
                setWalletCreated(true);
                setWalletInfo({
                    walletId: data.walletId,
                    balance: data.balance,
                });
                setMessage(data.message);
            } else {
                setMessage(data.message || "Error creating wallet.");
            }
        } catch (error) {
            setMessage("Error creating wallet. Please try again.");
        }
    };

    return (
        <div className="create-wallet-container">
            <h2>Create Wallet</h2>
            {walletCreated ? (
                <div className="wallet-info">
                    <p className="success-message">{message}</p>
                    <p><strong>Wallet ID:</strong> {walletInfo.walletId}</p>
                    <p><strong>Balance:</strong> ₦{walletInfo.balance}</p>
                    <button className="back-button" onClick={() => window.location.href = '/wallet'}>
                        Back to Wallet
                    </button>
                </div>
            ) : (
                <form onSubmit={handleCreateWallet} className="create-wallet-form">
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="username">Username:</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="create-button">
                        Create Wallet
                    </button>
                    {message && <p className="error-message">{message}</p>}
                    <a href="/login" className="signup-link">
                            Sign up here
                        </a>
                    
                </form>
            )}
        </div>
    );
};

export default CreateWallet;
