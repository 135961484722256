import React, { useState } from 'react';
import './CSS/CableSubscription.css';
import cableImage from '../Components/Assets/dstv-startimes-gotv-2473130009.png';

const CableSubscription = () => {
  const [provider, setProvider] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [amount, setAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to pay for cable subscription
    alert(`Paying ₦${amount} for ${provider} subscription on account ${accountNumber}`);
  };

  return (

    <div className="airtime-page">
    {/* Banner Image */}
    <div className="banner-container">
     <img src={cableImage} alt="Banner" className="cable-image" />
   </div>

    <div className="cable-subscription">
      <h2>Cable Subscription</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="provider">Cable Provider:</label>
          <select
            id="provider"
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
            required
          >
            <option value="">Select Provider</option>
            <option value="DSTV">DSTV</option>
            <option value="GOTV">GOTV</option>
            <option value="Startimes">Startimes</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="account-number">Account Number:</label>
          <input
            type="text"
            id="account-number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="amount">Amount (₦):</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <button type="submit">Pay Subscription</button>
      </form>
    </div>
    </div>
  );
};

export default CableSubscription;
