import React, { useState } from 'react';
import './CSS/ElectricityBillPayment.css';
import electricityImage from '../Components/Assets/images.jpeg-33-3987072757.jpg';

const ElectricityBillPayment = () => {
  const [provider, setProvider] = useState('');
  const [meterNumber, setMeterNumber] = useState('');
  const [amount, setAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to pay for electricity bill
    alert(`Paying ₦${amount} to ${provider} for meter ${meterNumber}`);
  };

  return (

    
    <div className="airtime-page">
    {/* Banner Image */}
    <div className="banner-container">
     <img src={electricityImage} alt="Banner" className="electricity-image" />
   </div>

    <div className="electricity-bill-payment">
      <h2>Electricity Bill Payment</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="provider">Electricity Provider:</label>
          <select
            id="provider"
            value={provider}
            onChange={(e) => setProvider(e.target.value)}
            required
          >
            <option value="">Select Provider</option>
            <option value="Eko">Eko Electricity</option>
            <option value="Ikeja">Ikeja Electricity</option>
            <option value="Abuja">Abuja Electricity</option>
            <option value="Enugu">Enugu Electricity</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="meter-number">Meter Number:</label>
          <input
            type="text"
            id="meter-number"
            value={meterNumber}
            onChange={(e) => setMeterNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="amount">Amount (₦):</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <button type="submit">Pay Electricity Bill</button>
      </form>
    </div>
    </div>
  );
};

export default ElectricityBillPayment;
