

const environment = process.env.REACT_APP_ENVIRONMENT;

// Define a mapping object for base URLs
const baseApiUrls = {
  production: process.env.REACT_APP_PRODUCTION_BaseApi_URL,
  staging: process.env.REACT_APP_STAGING_BaseApi_URL,
};

export const env = {
  baseApiUrls,
  environment,
};