// src/components/ProductSearchPage.jsx
import React from 'react';
import SearchBar from './SearchBar'; // Import the SearchBar component

// Sample product data
const products = [
  { id: 1, name: "Smartphone", price: "₦120,000", category: "Electronics" },
  { id: 2, name: "Laptop", price: "₦350,000", category: "Electronics" },
  { id: 3, name: "Headphones", price: "₦15,000", category: "Accessories" },
  { id: 4, name: "Wireless Mouse", price: "₦5,500", category: "Accessories" },
  { id: 5, name: "Smart TV", price: "₦220,000", category: "Electronics" },
  { id: 6, name: "Gaming Console", price: "₦180,000", category: "Gaming" },
  { id: 7, name: "Office Chair", price: "₦40,000", category: "Furniture" },
  { id: 8, name: "Bluetooth Speaker", price: "₦12,000", category: "Accessories" },
  { id: 9, name: "Action Camera", price: "₦65,000", category: "Electronics" },
  { id: 10, name: "Desk Lamp", price: "₦8,000", category: "Furniture" },
  // Add more products as needed
];

const ProductSearchPage = () => {
  return (
    <div>
      <h1>Product Search</h1>
      {/* Pass the products array to the SearchBar as a prop */}
      <SearchBar products={products} />
    </div>
  );
};

export default ProductSearchPage;
