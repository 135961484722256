import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify'; // For notifications
import '../../src/Pages/CSS/Orders.css'; // Include your CSS styles

const BASE_API_URL =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_PRODUCTION_BaseApi_URL
    : process.env.REACT_APP_STAGING_BaseApi_URL;

console.log('Using Base API URL:', BASE_API_URL);


const OrderPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
  const apiUrl = `${BASE_API_URL}/orders`;
      console.log('Fetching from URL:', apiUrl);
      try {
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'auth-token': localStorage.getItem("auth-token") || '',
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch orders');

        const data = await response.json();
        setOrders(data.payload || []); // Ensure it's an array
      } catch (error) {
        console.error('Error fetching orders:', error);
        toast.error('Failed to load orders. Please try again later.'); // Notify user
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="order-page-container">
      <h1>Your Orders</h1>
      {loading ? (
        <p>Loading orders...</p>
      ) : (
        <div className="orders-list">
          {orders.length === 0 ? (
            <p>No orders found.</p>
          ) : (
            orders.map((order) => (
              <div className="order-card" key={order.invoiceNumber}>
                <h2>Order #{order.invoiceNumber}</h2>
                <p>Date: {new Date(order.createdAt).toLocaleDateString()}</p>
                <p>Status: <span className={`status ${order.paymentStatus.toLowerCase()}`}>{order.paymentStatus}</span></p>
                <div className="order-items">
                  <h3>Items:</h3>
                  {order.items.map((item) => (
                    <div className="order-item" key={item.id}>
                      <img src={item.image} alt={item.name} />
                      <div className="item-details">
                        <h4>{item.name}</h4>
                        <p>Price: ₦{parseFloat(item.price).toFixed(2)}</p>
                        <p>Quantity: {item.quantity}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <p>Total: ₦{parseFloat(order.amount).toFixed(2)}</p>
                <button className="view-receipt">View Receipt</button>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default OrderPage;
