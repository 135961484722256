import React, { createContext, useEffect, useState } from "react";
import { backend_url } from "../App";
import { createFetcher } from "../utility";

export const ShopContext = createContext(null);
const environment = process.env.REACT_APP_ENVIRONMENT || "staging";

const ShopContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const getDefaultCart = () => {
    const savedCart = JSON.parse(localStorage.getItem("cart")) || {};
    return savedCart;
  };
  

  const [cartItems, setCartItems] = useState(getDefaultCart());
  const [wishlistItems, setWishlistItems] = useState({});

  useEffect(() => {
    // Fetch all products
    const fetchProducts = createFetcher({
      method: "get",
      url: `${backend_url}/product/allproducts`,
      headers: { "Content-Type": "application/json" },
      timeout: 60 * 6 * 1000,
      onError: (error) => console.error("Error fetching products:", error),
    });

    fetchProducts
      .trigger()
      .then((response) => {
        console.log("Fetched Products:", response.payload.payload);
        setProducts(response.payload.payload.data);
      })
      .catch((error) => console.error("Error fetching products:", error));

    // Fetch cart items if authenticated
    if (localStorage.getItem("auth-token")) {
      const fetchCartItems = createFetcher({
        method: "post",
        url: `${backend_url}/cart/getcart`,
        headers: {
          Accept: "application/json",
          "auth-token": localStorage.getItem("auth-token"),
          "Content-Type": "application/json",
        },
        data: {},
        timeout: 60 * 6 * 1000,
        onError: (error) => console.error("Error fetching cart:", error),
      });

      fetchCartItems
        .trigger()
        .then((response) => setCartItems(response.payload.payload.data))
        .catch((error) => console.error("Error fetching cart:", error));
    }
  }, []);

  const getCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = products.find((product) => product.id === Number(item));
        if (itemInfo) {
          totalAmount += cartItems[item] * itemInfo.new_price;
        }
      }
    }
    return totalAmount;
  };

  const getTotalCartAmount = () => getCartAmount();

  const getItemCount = () => Object.values(cartItems).reduce((acc, count) => acc + count, 0); // Define getItemCount

  const addToCart = (itemId) => {
    if (!localStorage.getItem("auth-token")) {
      alert("Please Login");
      return;
    }
    const updatedCart = { ...cartItems, [itemId]: (cartItems[itemId] || 0) + 1 };
    setCartItems(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart)); 

    const addCartFetcher = createFetcher({
      method: "post",
      url: `${backend_url}/addtocart`,
      headers: {
        Accept: "application/json",
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: { itemId },
      timeout: 60 * 6 * 1000,
      onError: (error) => console.error("Error adding to cart:", error),
    });

    addCartFetcher.trigger().catch((error) => {
       console.error("Error adding to cart:", error);
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
  });
  };

  const removeFromCart = (itemId) => {
    if (!localStorage.getItem("auth-token")) {
      alert("Please Login");
      return;
    }
    setCartItems((prev) => {
      const newCount = prev[itemId] - 1;
      return { ...prev, [itemId]: Math.max(newCount, 0) };
    });

    const removeCartFetcher = createFetcher({
      method: "post",
      url: `${backend_url}/removefromcart`,
      headers: {
        Accept: "application/json",
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: { itemId },
      timeout: 60 * 6 * 1000,
      onError: (error) => console.error("Error removing from cart:", error),
    });

    removeCartFetcher.trigger().catch((error) => console.error("Error removing from cart:", error));
  };

  const updateQuantity = (itemId, quantity) => {
    if (!localStorage.getItem("auth-token")) {
      alert("Please Login");
      return;
    }
    setCartItems((prev) => ({ ...prev, [itemId]: quantity }));

    const updateCartFetcher = createFetcher({
      method: "post",
      url: `${backend_url}/updatecart`,
      headers: {
        Accept: "application/json",
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: { itemId, quantity },
      timeout: 60 * 6 * 1000,
      onError: (error) => console.error("Error updating cart:", error),
    });

    updateCartFetcher.trigger().catch((error) => console.error("Error updating cart:", error));
  };

  // Define the  handleAddToWishlist function
  const  handleAddToWishlist = (itemId) => {
    if (!localStorage.getItem("auth-token")) {
      alert("Please Login");
      return;
    }
    // Find the product to add to the wishlist
  const itemToAdd = products.find((product) => product.id === itemId);
  if (!itemToAdd) return; // Item not found

    setWishlistItems((prev) => ({
      ...prev,
      [itemId]: prev[itemId] ? prev[itemId] + 1 : 1,
    }));
    
    // Remove the item from the cart
  setCartItems((prev) => {
    const newCart = { ...prev };
    delete newCart[itemId]; // Remove from cart
    return newCart;
  });

    const addWishlistFetcher = createFetcher({
      method: "post",
      url: `${backend_url}/ handleAddToWishlist`,
      headers: {
        Accept: "application/json",
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: { itemId },
      timeout: 60 * 6 * 1000,
      onError: (error) => console.error("Error adding to wishlist:", error),
    });

    addWishlistFetcher.trigger().catch((error) => console.error("Error adding to wishlist:", error));

  // Alert the user
  alert(`${itemToAdd.name} has been added to your wishlist!`);
  };


  return (
    <ShopContext.Provider value={{
      products,
      cartItems,
      addToCart,
      removeFromCart,
      getTotalCartAmount,
      getCartAmount,
      updateQuantity,
      getItemCount,
   
      showSearch,
      setShowSearch,
      searchTerm,
      setSearchTerm,
      wishlistItems,
      handleAddToWishlist
    }}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
