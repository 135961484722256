import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton"; // Import Skeleton loader
import "react-loading-skeleton/dist/skeleton.css"; // Skeleton styles
import aboutImage from "../Components/Assets/about-post.jpg"; // Import the image
import MetaTags from "../Components/MetaTags";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);
  const [activeFAQ, setActiveFAQ] = useState(null);

  useEffect(() => {
    // Simulate image loading delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay for loader
  }, []);

  const toggleFAQ = (index) => {
    setActiveFAQ(index === activeFAQ ? null : index);
  };

  const styles = {
    container: {
      padding: "50px 20px",
      backgroundColor: "#f8f8f8",
      borderRadius: "10px",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
      maxWidth: "1200px",
      margin: "40px auto",
      fontFamily: "'Arial', sans-serif",
      textAlign: "center",
    },
    heading: {
      fontSize: "3rem", // Increase font size for more emphasis
      fontWeight: "bold",
      marginBottom: "30px",
      color: "#333",
      textTransform: "uppercase", // Add text transformation for a bold look
      letterSpacing: "2px", // Spacing for better readability
    },
    subHeading: {
      fontSize: "2rem",
      fontWeight: "600",
      marginBottom: "15px",
      color: "#ff6f61", // Fancy color for subheadings
    },
    image: {
      maxWidth: "100%", // Ensure the image scales responsively
      height: "auto",
      margin: "30px 0",
      borderRadius: "10px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    },
    paragraph: {
      fontSize: "1.2rem",
      lineHeight: "1.8", // Better line height for readability
      color: "#555",
      margin: "20px 0",
      padding: "0 20px",
    },
    servicesList: {
      listStyleType: "none",
      padding: 0,
      margin: "30px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    listItem: {
      fontSize: "1.1rem",
      margin: "10px 0",
      color: "#444",
      padding: "12px 20px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
      width: "80%",
      transition: "transform 0.3s",
    },
    listItemHover: {
      transform: "scale(1.05)",
      backgroundColor: "#ff6f61",
      color: "#fff",
    },
    faqContainer: {
      marginTop: "60px",
      textAlign: "left",
    },
    faqItem: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
      marginBottom: "15px",
      padding: "20px",
      cursor: "pointer",
      transition: "background-color 0.3s",
    },
    faqQuestion: {
      fontSize: "1.4rem",
      fontWeight: "bold",
      color: "#333",
    },
    faqAnswer: {
      fontSize: "1.1rem",
      color: "#555",
      marginTop: "10px",
      maxHeight: "300px", // Limit the height for answers
      overflow: "hidden", // Prevents overflow of large answers
      transition: "max-height 0.3s ease", // Smooth toggle animation
    },
  };

  const faqs = [
    {
      question: "How do I make a payment?",
      answer: "You can make payments through Paystack, Stripe, or direct bank transfers. Simply choose the most convenient option during checkout.",
    },
    {
      question: "What are the delivery times?",
      answer: "Delivery times vary depending on your location. For local orders, deliveries take 2-5 days, while international orders can take 7-14 days.",
    },
    {
      question: "How do I track my order?",
      answer: "After placing an order, you will receive a tracking number via email. You can use this number to track your order on our website.",
    },
    {
      question: "Can I return a product?",
      answer: "Yes, we have a 14-day return policy. If you're unsatisfied with a product, contact our support for instructions on how to return it.",
    },
    {
      question: "Is there a warranty on products?",
      answer: "Most products come with a manufacturer's warranty. Please check the product details for specific warranty information.",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <MetaTags
        title="About Mbok Connect"
        description="Learn more about Mbok Connect and our mission to provide seamless utility payment services."
        keywords="about us, Mbok Connect, utility services, Nigeria"
      />

      {/* Skeleton Loader for the entire page */}
      {loading ? (
        <Skeleton height={600} width="100%" />
      ) : (
        <>
          {/* Banner Section */}
          <div style={{ width: "100%", marginBottom: "30px" }}>
            <img
              src={aboutImage}
              alt="About Us"
              style={{
                width: "100%",
                maxHeight: "450px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </div>

          <div style={styles.container}>
            <h1 style={styles.heading}>About Us</h1>

            <p style={styles.paragraph}>
              Welcome to Mbok Connect! We are committed to delivering seamless utility payment services and high-quality products. Our mission is to provide efficient, secure, and reliable solutions to enhance your daily life.
            </p>
            <p style={styles.paragraph}>
              At our company, we understand that shopping is a personal
              experience. That's why we offer personal shopping services
              strictly on request. Our goal is to cater to your unique needs and
              preferences.
            </p>
            <h2 style={styles.heading}>Our Services</h2>
            <ul style={styles.servicesList}>
              <li style={styles.listItem}>🌟 Seamless Utility Payments</li>
              <li style={styles.listItem}>🌟 Efficient Airtime/Data Recharge</li>
              <li style={styles.listItem}>🌟 Reliable Customer Support</li>
              <li style={styles.listItem}>🌟 Personalized Shopping Assistance</li>
            </ul>
            <p style={styles.paragraph}>
              Thank you for choosing Mbok Connect. We are excited to serve you and make your experience memorable!
            </p>
            {/* FAQ Section */}
            <div style={styles.faqContainer}>
              <h2 style={styles.subHeading}>Frequently Asked Questions (FAQs)</h2>
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  style={styles.faqItem}
                  onClick={() => toggleFAQ(index)}
                >
                  <div style={styles.faqQuestion}>{faq.question}</div>
                  {activeFAQ === index && (
                    <div style={styles.faqAnswer}>{faq.answer}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AboutUs;
