
import React, { createContext, useState } from 'react';

export const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const [wishlistItems, setWishlistItems] = useState([]);

  const addToWishlist = (item) => {
    const itemExists = wishlistItems.some((wishlistItem) => wishlistItem.id === item.id);
    if (!itemExists) {
        setWishlistItems((prevItems) => [...prevItems, item]); // Ensure this line works correctly
        alert(`${item.name} has been added to your wishlist!`);
    } else {
        alert(`${item.name} is already in your wishlist!`);
    }
};

  
  const removeFromWishlist = (itemId) => {
    setWishlistItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };

  return (
    <WishlistContext.Provider value={{  wishlistItems,  addToWishlist, removeFromWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};
