import React, { useState } from "react";

const PaymentConfirmation = ({ transactionDetails, onConfirm, onCancel }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState("wallet");

    const handlePayment = async () => {
        setLoading(true);
        setError(null);

        try {
            const requestBody = {
                ...transactionDetails,
                paymentMethod,
            };

            const response = await fetch("http://localhost:4000/api/rechargeAirtime", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                const errorData = await response.text();
                throw new Error(errorData || "Payment failed. Please try again.");
            }

            const result = await response.json();
            onConfirm(result);
        } catch (err) {
            console.error("Payment Error:", err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Ensure amount is treated as a number for display
    const amount = parseFloat(transactionDetails.amount) || 0;

    return (
        <div className="payment-confirmation">
            <h2>Please Confirm Your Transaction Details:</h2>
            <p>Product: {transactionDetails.product}</p>
            <p>Phone: {transactionDetails.phoneNumber}</p>
            <p>Amount: ₦ {amount.toFixed(2)} + ₦ 0.00 (Convenience Fee)</p>
            <p>Total Payable Amount: ₦ {amount.toFixed(2)}</p>
            <p>Transaction ID: {transactionDetails.transactionId}</p>
            <p>Status: initiated</p>

            <div className="payment-method">
                <h3>Choose Payment Method:</h3>
                <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
                    <option value="wallet">Wallet</option>
                    <option value="paystack">Paystack</option>
                    <option value="card">Card</option>
                </select>
            </div>

            {error && <div className="error-message">{error}</div>}
            <button onClick={handlePayment} disabled={loading}>
                {loading ? "Processing..." : "Pay Now"}
            </button>
            <button onClick={onCancel}>Cancel</button>
        </div>
    );
};

export default PaymentConfirmation;
