
import React from 'react';
import PropTypes from 'prop-types';

const MetaTags = ({ title, description, keywords }) => {
    return (
        <head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="robots" content="index, follow" />
        </head>
    );
};

MetaTags.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    keywords: PropTypes.string,
};

MetaTags.defaultProps = {
    keywords: '',
};

export default MetaTags;
