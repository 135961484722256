import React, { useContext } from "react";
import "./ProductDisplay.css";
import star_icon from "../Assets/star_icon.png";
import star_dull_icon from "../Assets/star_dull_icon.png";
import { ShopContext } from "../../Context/ShopContextProvider";
import { backend_url, currency } from "../../App";
import { WishlistContext } from '../../Context/WishlistContext';
import "../../Pages/CSS/Product.css";

const ProductDisplay = ({ product }) => {
  const { addToCart } = useContext(ShopContext);
  const { addToWishlist } = useContext(WishlistContext);


  const handleAddToWishlist = (item) => {
    addToWishlist(item);
    alert(`${item.name} has been added to your wishlist!`);
  };

  const handleAddToCart = (item) => {
    addToCart(item.id);
    alert(`${item.name} has been added to your cart!`); // Feedback alert for cart
  };

  

  return (
    <div className="productdisplay">
      <div className="productdisplay-left">
        <div className="productdisplay-img-list">
          {/* Use optional chaining and provide a fallback image */}
          <img src={backend_url + product.image} alt="img" />
          <img src={backend_url + product.image} alt="img" />
          <img src={backend_url + product.image} alt="img" />
          <img src={backend_url + product.image} alt="img" />
        </div>
        <div className="productdisplay-img">
        <img className="productdisplay-main-img" src={backend_url + product.image} alt="img" />
        </div>
      </div>
      <div className="productdisplay-right">
        <h1>{product.name}</h1>
        <div className="productdisplay-right-stars">
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_icon} alt="" />
          <img src={star_dull_icon} alt="" />
          <p>(122)</p>
        </div>
        <div className="productdisplay-right-prices">
          {/* Only display old price if it exists */}
          {product?.old_price && (
            <div className="productdisplay-right-price-old">
              {currency}
              {product.old_price}
            </div>
          )}
          <div className="productdisplay-right-price-new">
            {currency}
            {product?.new_price || "0.00"}
          </div>
        </div>
        <div className="productdisplay-right-description">
          {product?.description || "No description available."}
        </div>
        <div className="productdisplay-right-size">
          <h1>Select Size</h1>
          <div className="productdisplay-right-sizes">
            <div>S</div>
            <div>M</div>
            <div>L</div>
            <div>XL</div>
            <div>XXL</div>
          </div>
        </div>
      
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <button onClick={() =>handleAddToCart(product)}>ADD TO CART</button>
        <button 
          className="wishlist-button" 
          onClick={() => handleAddToWishlist(product)} // Use the actual product object
        >
          Add to Wishlist
        </button>
      </div>
        <p className="productdisplay-right-category">
          <span>Category :</span> {product?.category || "Category not specified"}
        </p>
        <p className="productdisplay-right-category">
          <span>Tags :</span> {product?.tags?.join(", ") || "No tags available"}
        </p>
      </div>
    </div>
  );
};

export default ProductDisplay;
