import React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const PaystackCheckout = () => {
    const location = useLocation();
    const { formData, totalAmount } = location.state;

    const handlePaystackPayment = async () => {
        // Logic for initiating Paystack payment
        try {
            const response = await axios.post('/paystack/payment', { amount: totalAmount });
            const paystackUrl = response.data.authorization_url;
            window.location.href = paystackUrl; // Redirect to Paystack payment page
        } catch (error) {
            console.error("Error processing Paystack payment:", error);
        }
    };

    return (
        <div>
            <h2>Paystack Checkout</h2>
            <p>Total Amount: ₦{totalAmount.toLocaleString()}</p>
            <button onClick={handlePaystackPayment}>Pay Now</button>
        </div>
    );
};

export default PaystackCheckout;
