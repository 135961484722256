import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ShopContext } from "../Context/ShopContextProvider";
import { assets } from "../Components/Assets/assets";
import Item from "../Components/Item/Item"; // Import your Item component
import axios from "axios"; // Import Axios
import { backend_url } from "../App";
import Skeleton from "react-loading-skeleton"; // Import Skeleton
import "react-loading-skeleton/dist/skeleton.css"; // Import skeleton styles
import bannerImage from "../Components/Assets/Orange Red New Collections Product Sale Banner.png";
import MetaTags from "../Components/MetaTags";

const Mens = () => {
  const { search } = useContext(ShopContext);
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [sortType, setSortType] = useState("relevant");
  const [isFiltered, setIsFiltered] = useState(false); // Fixed here
  const [loading, setLoading] = useState(true);

  // Fetch product data only once on component mount
  useEffect(() => {
    const fetchProduct = async () => {
      if (productId) {
        try {
          const response = await fetch(
            `${backend_url}/api/products/${productId}`
          );
          const data = await response.json();
          setProduct(data); // Update the product state with fetched data
          setLoading(false); // Loading done
        } catch (error) {
          console.error("Error fetching product:", error);
          setLoading(false);
        }
      }
    };

    fetchProduct();
  }, [productId]); // Re-run the effect when productId changes

  // Fetch All Products
  const fetchInfo = () => {
    setLoading(true);
    axios
      .get(`${backend_url}/product/allproducts`)
      .then((res) => {
        console.log("Response:", res.data.payload.data);
        setAllProducts(res.data.payload.data);
        setFilterProducts(res.data.payload.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching products:", err);
        setLoading(false);
        alert("Failed to load products. Please try again later.");
      });
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  // Toggle Category Function
  const toggleCategory = (e) => {
    const value = e.target.value;
    setCategory((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // Toggle SubCategory Function
  const toggleSubCategory = (e) => {
    const value = e.target.value;
    setSubCategory((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // Apply filters based on category, subCategory, and search
  const applyFilter = useCallback(() => {
    let productsCopy = [...allProducts];

    if (search || category.length > 0 || subCategory.length > 0) {
      setIsFiltered(true); // Set isFiltered to true if any filter is applied

      if (search) {
        productsCopy = productsCopy.filter((item) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        );
      }

      if (category.length > 0) {
        productsCopy = productsCopy.filter((item) =>
          category.includes(item.category)
        );
      }

      if (subCategory.length > 0) {
        productsCopy = productsCopy.filter((item) =>
          subCategory.includes(item.subCategory)
        );
        if (category.length > 0) {
          productsCopy = productsCopy.filter((item) =>
            category.some((cat) => item.category.includes(cat))
          );
        }
      }
    } else {
      setIsFiltered(false); // Set isFiltered to false if no filters are applied
    }

    return productsCopy;
  }, [allProducts, search, category, subCategory]);

  // Sort Products
  const sortProduct = useCallback(
    (productsToSort) => {
      if (!Array.isArray(productsToSort)) {
        return [];
      }

      let sortedProducts = [...productsToSort];

      switch (sortType) {
        case "low-high":
          sortedProducts.sort((a, b) => a.new_price - b.new_price);
          break;
        case "high-low":
          sortedProducts.sort((a, b) => b.new_price - a.new_price);
          break;
        default:
          break;
      }

      return sortedProducts;
    },
    [sortType]
  );

  // Apply filters when dependencies change
  useEffect(() => {
    const filtered = applyFilter();
    const sorted = sortProduct(filtered);
    setFilterProducts(sorted);
  }, [applyFilter, sortProduct]);

  return (
    <div style={{ padding: "20px" }}>
      <MetaTags
        title="About Mbok Connect"
        description="Learn more about Mbok Connect and our mission to provide seamless utility payment services."
        keywords="about us, Mbok Connect, utility services, Nigeria"
      />

      {/* Banner Section */}
      <div style={{ width: "100%", marginBottom: "20px" }}>
        <img
          src={bannerImage}
          alt="Banner"
          style={{
            width: "100%",
            maxHeight: "400px", // Adjust the height to your preference
            objectFit: "cover", // Ensures the image covers the area while maintaining aspect ratio
            borderRadius: "8px",
          }}
        />
      </div>

      {/* Search Bar */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ position: "relative", width: "60%" }}>
          <input
            type="text"
            placeholder="Search..."
            onChange={(e) => search.setSearch(e.target.value)}
            style={{
              width: "100%",
              padding: "10px",
              paddingLeft: "40px",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          />
          <img
            src={assets.search_icon}
            alt="Search"
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              width: "20px",
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {/* Filter Section */}
        <aside>
          <p
            style={{
              fontSize: "1.25rem",
              cursor: "pointer",
              fontWeight: "bold",
            }}
          >
            FILTERS
          </p>
        </aside>

        {/* Sort Section */}
        <div>
          <select
            onChange={(e) => setSortType(e.target.value)}
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              backgroundColor: "#fff",
            }}
          >
            <option value="relevant">Sort by: Relevant</option>
            <option value="low-high">Sort by: Low to High</option>
            <option value="high-low">Sort by: High to Low</option>
          </select>
        </div>
      </div>

      <div style={{ display: "flex", gap: "20px" }}>
        {/* Filter Options */}
        <aside style={{ minWidth: "200px" }}>
          {/* Category Container */}
          <div
            style={{
              marginBottom: "20px",
              border: "1px solid black",
              padding: "10px",
              borderRadius: "4px",
              fontSize: "0.9rem",
            }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
              CATEGORIES
            </p>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <label>
                <input
                  type="checkbox"
                  value="Utility Bills"
                  onChange={toggleCategory}
                />{" "}
                Utility Bills
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Airtime Recharge"
                  onChange={toggleCategory}
                />{" "}
                Airtime Recharge
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Internet/Data"
                  onChange={toggleCategory}
                />{" "}
                Internet/Data
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Cable Subscription"
                  onChange={toggleCategory}
                />{" "}
                Cable Subscription
              </label>
            </div>
          </div>

          {/* SubCategory Container */}
          <div
            style={{
              border: "1px solid black",
              padding: "10px",
              borderRadius: "4px",
              fontSize: "0.9rem",
            }}
          >
            <p style={{ fontWeight: "bold", marginBottom: "10px" }}>TYPE</p>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <label>
                <input
                  type="checkbox"
                  value="Electricity"
                  onChange={toggleSubCategory}
                />{" "}
                Electricity
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Water"
                  onChange={toggleSubCategory}
                />{" "}
                Water
              </label>
              <label>
                <input
                  type="checkbox"
                  value="Internet"
                  onChange={toggleSubCategory}
                />{" "}
                Internet
              </label>
              <label>
                <input
                  type="checkbox"
                  value="DStv"
                  onChange={toggleSubCategory}
                />{" "}
                DStv
              </label>
            </div>
          </div>
        </aside>

        {/* Main Products Section */}
        <main style={{ flexGrow: 1 }}>
          {loading ? (
            // Display Loading Skeleton
            <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
              {Array.from({ length: 8 }).map((_, index) => (
                <Skeleton key={index} height={200} width={150} />
              ))}
            </div>
          ) : (
            // Display Filtered Products
            <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
              {filterProducts.length > 0 ? (
                filterProducts.map((product) => {
                  // console.log(product); // Log the product object
                  // return <Item key={product.id} item={product} />;
                  return <Item key={product.id} {...product} />;
                })
              ) : (
                <p>No products found.</p>
              )}
            </div>
          )}
          {/* Message based on isFiltered state */}
          {isFiltered && <p style={{ color: "gray" }}>Filters applied.</p>}
        </main>
      </div>
    </div>
  );
};

export default Mens;
