import React, { useEffect, useState } from 'react';
import axios from 'axios'; // Import Axios
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton styles
import Hero from '../Components/Hero/Hero';
import Popular from '../Components/Popular/Popular';
import Offers from '../Components/Offers/Offers';
import NewCollections from '../Components/NewCollections/NewCollections';
import NewsLetter from '../Components/NewsLetter/NewsLetter';
import { backend_url } from "../App";
import MetaTags from '../Components/MetaTags';

const Shop = () => {
  const [popular, setPopular] = useState([]);
  const [newcollection, setNewCollection] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to fetch product data
  const fetchInfo = async () => {
    try {
      // Fetch popular products
      const popularResponse = await axios.get(`${backend_url}/product/popularinwomen?gSearch=women&option=CATE`);
      setPopular(popularResponse.data.payload.data); // Update state with popular products

      // Fetch new collections
      const newCollectionResponse = await axios.get(`${backend_url}/product/newcollections`);
      setNewCollection(newCollectionResponse.data.payload.data); // Update state with new collections
    } catch (error) {
      console.error("Error fetching data:", error); // Log any errors
    } finally {
      setLoading(false); // Set loading to false after data fetch
    }
  };

  useEffect(() => {
    fetchInfo(); // Call fetchInfo when component mounts
  }, []);

  return (
    <div>
          <MetaTags
                title="Mbok Connect - Your Utility Payment Solution"
                description="Pay your utility bills, recharge airtime, and manage subscriptions effortlessly with Mbok Connect."
                keywords="utility payment, airtime recharge, subscriptions, Nigeria"
            />
      {loading ? (
        <div>
          <Skeleton height={200} width={200} count={5} /> {/* Skeletons for loading */}
          <Skeleton width={`80%`} count={3} />
        </div>
      ) : (
        <>
          <Hero />
          <Popular data={popular} />
          <Offers />
          <NewCollections data={newcollection} />
          <NewsLetter />
        </>
      )}
    </div>
  );
};

export default Shop;
