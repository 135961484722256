import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './CSS/UtilityServices.css'; // You can style this component as needed
import bannerImage from '../../src/Components/Assets/banner_kids.png';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton styles

const UtilityServices = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading time (e.g., fetching services)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="utility-services">
      {/* Banner Image */}
      <div className="banner-container">
        <img src={bannerImage} alt="Banner" className="banner-image" />
      </div>

      <h1 className="utility-header">Utility & Payment Services</h1>

      <div className="services-container">
        {loading ? (
          // Display Skeleton Loader while loading
          <div className="skeleton-container">
            <Skeleton height={50} width={200} style={{ margin: '10px 0' }} />
            <Skeleton height={50} width={200} style={{ margin: '10px 0' }} />
            <Skeleton height={50} width={200} style={{ margin: '10px 0' }} />
            <Skeleton height={50} width={200} style={{ margin: '10px 0' }} />
            <Skeleton height={50} width={200} style={{ margin: '10px 0' }} />
          </div>
        ) : (
          <>
            {/* Airtime Recharge Link */}
            <div className="service-item">
              <Link to="/airtime-recharge">
                <button className="service-button">Airtime Recharge</button>
              </Link>
            </div>

            {/* Data Subscription Link */}
            <div className="service-item">
              <Link to="/data-subscription">
                <button className="service-button">Data Subscription</button>
              </Link>
            </div>

            {/* Internet Subscription Link */}
            <div className="service-item">
              <Link to="/internet-subscription">
                <button className="service-button">Internet Subscription</button>
              </Link>
            </div>

            {/* Cable Subscription Link */}
            <div className="service-item">
              <Link to="/cable-subscription">
                <button className="service-button">Cable Subscription</button>
              </Link>
            </div>

            {/* Electricity Bill Payments Link */}
            <div className="service-item">
              <Link to="/electricity-bill-payment">
                <button className="service-button">Electricity Bill Payments</button>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UtilityServices;
