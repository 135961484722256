import { fetcher } from "netwrap";
import axios from "axios";

const serializeQueryParams = (params) => {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
};

const createFetcher = ({
  method,
  url,
  params = {},
  data = {},
  query = {},
  headers = {},
  timeout = "60 * 6 * 1000",
  onError,
}) => {
  return fetcher({
    queryFn: async () => {
      let fullUrl = url;
      // Replace params in the URL string
      if (Object.keys(params).length > 0) {
        Object.keys(params).forEach((key) => {
          console.log(`Key: ${key}, Value: ${params[key]}`);
          console.log(`Before replace: ${fullUrl}`);
          // Replace :id with the corresponding value
          fullUrl = fullUrl.replace(`:${key}`, encodeURIComponent(params[key]));
          console.log(`After replace: ${fullUrl}`);
        });
      }

      // Append query parameters to the URL string
      if (Object.keys(query).length > 0) {
        const queryString = serializeQueryParams(query);
        fullUrl = `${url}?${queryString}`;
      }

      const config = { method, url: fullUrl, headers, timeout };

      if (method.toLowerCase() !== "get" && method.toLowerCase() !== "delete") {
        config.data = data;
      }
      console.log(config);
      try {
        const response = await axios(config);
        return response.data;
      } catch (error) {
        const status = error.response?.status;
        const statusCode = error.code;

        // If status is 500 or higher, call onError and throw the error
        if (status > 500) {
          if (statusCode) {
            if (onError) {
              onError(error); // Call the onError callback if provided
            }
            throw error; // Throw the error to be caught by the fetcher's onError handler
          }
          // console.log(error.response.data, "am hereeeeee");
          if (onError) {
            onError(error); // Call the onError callback if provided
          }
          throw error; // Throw the error to be caught by the fetcher's onError handler
        }

        return (
          error.response?.data || {
            error: error.message + " An error occurred",
          }
        );
      }
    },
    onStartQuery: () => console.log(`${method.toUpperCase()} query started`),
    onSuccess: (data) => {
      console.log(`${method.toUpperCase()} query successful`, data);
    },
    onError: (error) => {
      console.error(`${method.toUpperCase()} query failed`, error);
      if (onError) {
        onError(error); // Call the onError callback if provided
      }
    },
    onFinal: () => console.log(`${method.toUpperCase()} query completed`),
  });
};

export { createFetcher };
