import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { ShopContext } from "../Context/ShopContextProvider";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import "./CSS/PlaceOrder.css";
import PaystackPop from '@paystack/inline-js';

const PostCardContainer = ({ title, content }) => {
  return (
    <div className="postcard-container">
      <div className="postcard-header">
        <h3>{title}</h3>
        <span className="arrow"></span>
      </div>
      <div className="postcard-body">{content}</div>
    </div>
  );
};

const PlaceOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getCartAmount } = useContext(ShopContext);
  const [currentStage, setCurrentStage] = useState(1);
  const [method, setMethod] = useState("cod");
  const { cartItems = [] } = location.state || {};
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedShippingFee, setSelectedShippingFee] = useState(0); // Initialize shipping fee state

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    street: "",
    city: "",
    state: "",
    country: "Nigeria",
    landmark: "",
  });

  // Calculate cart subtotal using getCartAmount
  const cartSubtotal = getCartAmount(cartItems); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleShippingChange = (e) => {
    const shippingFeeMap = {
      standard: 500,
      express: 1000,
      sameDay: 1500,
      GIG: 8000,
      GUO: 6500,
      InternationalDHL: 150000,
    };
    const selectedMethod = e.target.value;
    const fee = shippingFeeMap[selectedMethod] || 0;
    setSelectedShippingFee(fee);
  };

  // Update the totalAmount whenever cart amount or shipping fee changes
  useEffect(() => {
    const calculatedTotal = cartSubtotal + selectedShippingFee;  // Total is subtotal plus shipping fee
    setTotalAmount(calculatedTotal);
  }, [cartSubtotal, selectedShippingFee]);

  const handleNext = () => {
    // Validate inputs based on the current stage
    if (currentStage === 1) {
      if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone) {
        toast.error("Please fill all required personal details.");
        return;
      }
    } else if (currentStage === 2) {
      if (!formData.street || !formData.city || !formData.state || !formData.country) {
        toast.error("Please fill all required shipping details.");
        return;
      }
    }

    setCurrentStage((prevStage) => prevStage + 1);
  };


  const handleBack = () => {
    setCurrentStage((prevStage) => Math.max(prevStage - 1, 1)); // Prevent going below stage 1
  };

  

  const handleCheckout = async () => {
    if (!totalAmount) {
      toast.error("Unable to calculate the total amount. Please check your order.");
      return;
    }

    const orderData = {
      items: cartItems,
      shippingDetails: formData,
      paymentMethod: method,
      totalAmount: totalAmount,
      paymentStatus: method === "cod" ? "Pending" : "Paid",
      orderId: uuidv4(),
    };

    switch (method) {
      case "paystack":
        const paystack = new PaystackPop();
        const email = formData.email; // Use email from formData
        const amount = totalAmount * 100; // Convert to kobo

        paystack.newTransaction({
          key: 'pk_test_ca9069ad2f3659576f1f836904b3a09c2c942640', // Replace with your actual public key
          email,
          amount,
          onSuccess: async (transaction) => {
            // Handle successful transaction here
            await addOrderToDatabase(orderData); // Save the order
             // Verify payment on the backend
          try {
            const response = await axios.post('/verify-payment', {
              reference: transaction.reference, // Send the transaction reference
            });
             // Check if the payment was verified successfully
             if (response.data.status) {
            toast.success("Payment successful. Order placed.");
            navigate("/success", { state: { formData, totalAmount, paymentMethod: "Paystack" } });
          } else {
            toast.error("Payment verification failed.");
          }
        } catch (error) {
          console.error('Error verifying payment:', error);
          toast.error("Error verifying payment. Please try again.");
        }
          },
          onCancel: () => {
            // Handle cancellation here
            toast.error("Payment was cancelled.");
          },
        });
        break;

        
        case "stripe":
          try {
            // Send totalAmount in NGN to the backend for conversion and Stripe session creation
            const response = await axios.post('http://localhost:4000/create-checkout-session', {
              amount: totalAmount,
            });
    
            // Redirect to the Stripe checkout page URL from the response
            window.location.href = response.data.url;
          } catch (error) {
            console.error("Error initiating Stripe checkout:", error);
            toast.error("Failed to redirect to payment page.");
          }
          break;

      case "cod":
      try {
        await addOrderToDatabase(orderData); // Save the order
        toast.success("Order placed via Cash on Delivery successfully.");
         // Redirect to success page with order details
         navigate("/success", { state: { formData, totalAmount, paymentMethod: "COD" } });
        } catch (error) {
          console.error("Error adding order:", error);
          toast.error("There was an error processing your order. Please try again.");
        }
        break;
      default:
        toast.error("Please select a payment method.");
    }
  };

  // Function to save the order to your backend
  const addOrderToDatabase = async (orderData) => {
    try {
      const response = await fetch('http://localhost:4000/api/v1/orders', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.message || 'Failed to add order'}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error("Order saving error:", error);
      throw error; // rethrow to handle in calling function
    }
  };
  

  return (
    <div className="place-order-container">
      {/* Breadcrumbs Navigation */}
      <div className="breadcrumbs-container">
        <div className="breadcrumbs">
          <div className={`breadcrumb-item ${currentStage >= 1 ? "active" : ""}`}>
            <span className={`number ${currentStage >= 1 ? "active" : ""}`}>1</span>
            Personal Details
            {currentStage >= 1 && <span className="arrow"></span>}
          </div>
          <div className={`breadcrumb-item ${currentStage >= 2 ? "active" : ""}`}>
            <span className={`number ${currentStage >= 2 ? "active" : ""}`}>2</span>
            Shipping
            {currentStage >= 2 && <span className="arrow"></span>}
          </div>
          <div className={`breadcrumb-item ${currentStage >= 3 ? "active" : ""}`}>
            <span className={`number ${currentStage >= 3 ? "active" : ""}`}>3</span>
            Review Order
            {currentStage >= 3 && <span className="arrow"></span>}
          </div>
          <div className={`breadcrumb-item ${currentStage === 4 ? "active" : ""}`}>
            <span className={`number ${currentStage === 4 ? "active" : ""}`}>4</span>
            Payment
          </div>
        </div>
      </div>

      {/* Personal Details Form */}
      {currentStage === 1 && (
        <PostCardContainer
          title="Personal Details"
          content={
            <>
              <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} required className="form-input" />
              <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} required className="form-input" />
              <input type="email" name="email" placeholder="Email Address" value={formData.email} onChange={handleChange} required className="form-input" />
              <input type="tel" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} required className="form-input" />
              <div className="button-group">
                <button onClick={handleNext} className="btn-next">Next</button>
              </div>
            </>
          }
        />
      )}

      {/* Shipping Details Form */}
      {currentStage === 2 && (
        <PostCardContainer
          title="Shipping Details"
          content={
            <>
              <input type="text" name="street" placeholder="Street Address" value={formData.street} onChange={handleChange} required className="form-input" />
              <input type="text" name="city" placeholder="City" value={formData.city} onChange={handleChange} required className="form-input" />
              <select name="state" value={formData.state} onChange={handleChange} required className="form-input">
                <option value="" disabled>Select State</option>
                {/* List of states */}
                <option value="Abia">Abia</option>
                <option value="Adamawa">Adamawa</option>
                <option value="Akwa Ibom">Akwa Ibom</option>
                <option value="Anambra">Anambra</option>
                <option value="Bauchi">Bauchi</option>
                <option value="Bayelsa">Bayelsa</option>
                <option value="Benue">Benue</option>
                <option value="Borno">Borno</option>
                <option value="Cross River">Cross River</option>
                <option value="Delta">Delta</option>
                <option value="Ebonyi">Ebonyi</option>
                <option value="Edo">Edo</option>
                <option value="Ekiti">Ekiti</option>
                <option value="Enugu">Enugu</option>
                <option value="Gombe">Gombe</option>
                <option value="Imo">Imo</option>
                <option value="Jigawa">Jigawa</option>
                <option value="Kaduna">Kaduna</option>
                <option value="Kano">Kano</option>
                <option value="Kogi">Kogi</option>
                <option value="Kwara">Kwara</option>
                <option value="Lagos">Lagos</option>
                <option value="Nasarawa">Nasarawa</option>
                <option value="Niger">Niger</option>
                <option value="Ogun">Ogun</option>
                <option value="Ondo">Ondo</option>
                <option value="Osun">Osun</option>
                <option value="Oyo">Oyo</option>
                <option value="Plateau">Plateau</option>
                <option value="Rivers">Rivers</option>
                <option value="Sokoto">Sokoto</option>
                <option value="Taraba">Taraba</option>
                <option value="Yobe">Yobe</option>
                <option value="Zamfara">Zamfara</option>
                <option value="Federal Capital Territory">Federal Capital Territory</option>
              </select>
              <input type="text" name="landmark" placeholder="Landmark (optional)" value={formData.landmark} onChange={handleChange} className="form-input" />

               {/* New Zipcode Input */}
        <input
          type="text"
          name="zipcode"
          placeholder="Zip Code"
          value={formData.zipcode}
          onChange={handleChange}
          required
          className="form-input"
        />

          {/* New Country Input */}
          <input
          type="text"
          name="country"
          placeholder="Country"
          value={formData.country}
          onChange={handleChange}
          required
          className="form-input"
        />

              {/* Shipping Method Dropdown */}
              <div>
                <label htmlFor="shippingMethod">Shipping Method:</label>
                <select id="shippingMethod" name="shippingMethod" onChange={handleShippingChange} className="form-input">
                  <option value="" disabled>Select Shipping Method</option>
                  <option value="standard">Standard Shipping (₦500)</option>
                  <option value="express">Express Shipping (₦1000)</option>
                  <option value="sameDay">Same Day Delivery (₦1500)</option>
                  <option value="GIG">GIG (₦8000)</option>
                  <option value="GUO">GUO (₦6500)</option>
                  <option value="InternationalDHL">International DHL (₦150000)</option>
                </select>
              </div>

              <div className="button-group">
                <button onClick={handleBack} className="btn-back">Back</button>
                <button onClick={handleNext} className="btn-next">Next</button>
              </div>
            </>
          }
        />
      )}

      {/* Review Order */}
      {currentStage === 3 && (
        <PostCardContainer
          title="Review Order"
          content={
            <>
              <h4>Order Summary:</h4>
              <p>Subtotal: ₦{cartSubtotal.toLocaleString()}</p>
              <p>Shipping Fee: ₦{selectedShippingFee.toLocaleString()}</p>
              <h4>Total: ₦{totalAmount.toLocaleString()}</h4>
              <div className="button-group">
                <button onClick={handleBack} className="btn-back">Back</button>
                <button onClick={handleNext} className="btn-next">Proceed to Payment</button>
              </div>
            </>
          }
        />
      )}

      {/* Payment Method Selection */}
      {currentStage === 4 && (
        <PostCardContainer
          title="Select Payment Method"
          content={
            <>
              <div>
              <h4>Select Payment Method</h4>
                <label>
                  <input type="radio" value="paystack" checked={method === "paystack"} onChange={() => setMethod("paystack")} />
                  Paystack
                </label>
                <label>
                  <input type="radio" value="stripe" checked={method === "stripe"} onChange={() => setMethod("stripe")} />
                  Stripe
                </label>
                <label>
                  <input type="radio" value="cod" checked={method === "cod"} onChange={() => setMethod("cod")} />
                  Cash on Delivery
                </label>
              </div>
              <div className="button-group">
                <button onClick={handleBack} className="btn-back">Back</button>
                <button onClick={handleCheckout} className="btn-next">Confirm Order</button>
              </div>
            </>
          }
        />
      )}
    </div>
  );
};

export default PlaceOrder;
