// src/components/JivoChat.js
import  { useEffect } from 'react';

const JivoChat = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = '//code.jivosite.com/widget/Dly8IU2KOH';
    script.async = true;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // No need to render anything visually
};

export default JivoChat;
