import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';  
import "./stripeCheckout.css";

// Load your Stripe public key outside the component
const stripePromise = loadStripe("pk_test_51QCbYSAD2kinhv4AcA2Fw1zgIjOTICOahTJlZELDs2bFGBSrvAqIzO5f9lAn2UiD9OyKDCmiMT1X3PxiqJRKVszv00mWwl0sC7");

const StripeCheckout = ({ dpmCheckerLink }) => {
    const location = useLocation();
    const { formData, totalAmount } = location.state;

    const [message, setMessage] = useState(null);

    // Fetch the client secret when the component mounts
    useEffect(() => {
        const fetchClientSecret = async () => {
            try {
                const response = await axios.post('http://localhost:4000/create-checkout-session', { amount: totalAmount });

                
                // Redirect to Stripe Checkout
                window.location.href = response.data.url;
            } catch (error) {
                console.error("Error fetching client secret:", error);
                setMessage("Unable to fetch payment details.");
            }
        };

        fetchClientSecret();
    }, [totalAmount]);

    return (
        <div>
            <h2>Stripe Embedded Checkout</h2>
            {message && <div id="payment-message">{message}</div>}
            {/* Dynamic payment methods annotation */}
            <div id="dpm-annotation">
                <p>
                    Payment methods are dynamically displayed based on customer location, order amount, and currency.&nbsp;
                    <a href={dpmCheckerLink} target="_blank" rel="noopener noreferrer" id="dpm-integration-checker">
                        Preview payment methods by transaction
                    </a>
                </p>
            </div>
        </div>
    );
};

export default StripeCheckout;