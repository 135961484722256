
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AUTO_LOGOUT_TIME = 15 * 60 * 1000; // 15 minutes in milliseconds

const AutoLogout = () => {
  const navigate = useNavigate();
  let logoutTimer;

  // Function to log out the user
  const handleLogout = () => {
    localStorage.removeItem('authToken'); // Adjust according to your authentication logic
    navigate('/login'); // Redirect to login page
    alert('You have been logged out due to inactivity.');
  };

  // Reset the auto-logout timer on user activity
  const resetLogoutTimer = () => {
    if (logoutTimer) clearTimeout(logoutTimer);
    logoutTimer = setTimeout(handleLogout, AUTO_LOGOUT_TIME);
  };

  useEffect(() => {
    // Track user activity and reset logout timer
    const events = ['mousemove', 'keydown', 'click'];
    const resetTimer = () => resetLogoutTimer();

    events.forEach(event => window.addEventListener(event, resetTimer));
    resetLogoutTimer(); // Start the timer on mount

    return () => {
      events.forEach(event => window.removeEventListener(event, resetTimer));
      if (logoutTimer) clearTimeout(logoutTimer);
    };
  }, []);

  return null; // This component does not render anything
};

export default AutoLogout;
