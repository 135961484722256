// Wallet.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopUpForm from './TopUpForm';
import WithdrawalForm from './NewWithdrawForm';
import TransferForm from './TransferForm';
import TransactionHistory from './TransactionHistory';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController } from 'chart.js'; 
import { Bar } from 'react-chartjs-2'; 
import { motion } from 'framer-motion'; // Import Framer Motion
import Slider from 'react-slick'; 
import { FaPlusCircle } from 'react-icons/fa';
import "./CSS/Wallet.css";
import secureIcon from '../Components/Assets/secure-icon.png';



// Register necessary components
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, BarController);

const BASE_URL = "http://localhost:4000"; 



const Wallet = () => {
    const [email, setEmail] = useState("");
    const [userId, setUserId] = useState("");
    const [walletId, setWalletId] = useState("");
    const [walletMessage, setWalletMessage] = useState("");
    const [walletBalance, setWalletBalance] = useState(null);
    const [balance, setBalance] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [rewardPoints, setRewardPoints] = useState(0);
    const [searchQuery, setSearchQuery] = useState(''); 
    const [showUserDetails, setShowUserDetails] = useState(false);

    const navigate = useNavigate(); // Initialize navigation

       // Fetch wallet details function
       const getWalletDetails = async (userId) => {
        const response = await fetch(`${BASE_URL}/api/wallet/details/${userId}`); 
        return response.json();
    };


       // Fetch wallet details on component mount
     
        const fetchWalletDetails = async () => {
            if (userId) {
                try {
                const response = await fetch(`http://localhost:4000/api/wallet/balance/${userId}`);
                const result = await response.json();
                if (result.walletBalance !== undefined) {
                    setWalletBalance(result.walletBalance);
                    setWalletId(result.walletId);
                    setWalletMessage("Wallet details retrieved successfully!");
                } else {
                    setWalletMessage(result.message || "Failed to retrieve wallet details.");
                }
            } catch (error) {
                console.error("Error fetching wallet details:", error);
                setWalletMessage("Error retrieving wallet details.");
            }
        }
    };

        useEffect(() => {
        fetchWalletDetails();
    }, [userId]); // Dependency on userId

    // Navigate to CreateWallet page
    const handleNavigateToCreateWallet = () => {
        navigate('/create-wallet');
    };

    const addTransaction = (type, amount) => {
        const newTransaction = {
            date: new Date().toLocaleString(),
            type: type,
            amount: parseFloat(amount),
            status: 'completed',
        };
        setTransactions([...transactions, newTransaction]);
    };

    const handleTopUp = (amount) => {
        setBalance(balance + parseFloat(amount));
        addTransaction('Top Up', amount);
        calculateRewardPoints(amount);
    };

    const handleWithdraw = (amount) => {
        if (amount <= balance) {
            setBalance(balance - parseFloat(amount));
            addTransaction('Withdraw', amount);
            calculateRewardPoints(amount);
        } else {
            alert('Insufficient balance for withdrawal');
        }
    };

    const handleTransfer = (recipient, amount) => {
        if (amount <= balance) {
            setBalance(balance - parseFloat(amount));
            addTransaction(`Transfer to ${recipient}`, amount);
            calculateRewardPoints(amount);
        } else {
            alert('Insufficient balance for transfer');
        }
    };

    const calculateRewardPoints = (amount) => {
        const points = Math.floor(amount / 10); // Example: 1 point per $10
        setRewardPoints(prevPoints => prevPoints + points);
    };

    useEffect(() => {
        setBalance(1000); // Example: Fetch balance from API
        setTransactions([
            { date: new Date().toLocaleString(), type: 'Top Up', amount: 1000, status: 'completed' },
            { date: new Date().toLocaleString(), type: 'Withdraw', amount: 200, status: 'completed' },
            // Additional mock transactions for charting
            { date: new Date().toLocaleString(), type: 'Top Up', amount: 300, status: 'completed' },
            { date: new Date().toLocaleString(), type: 'Transfer to Alice', amount: 150, status: 'completed' },
        ]);
    }, []);

        // Chart Data
        const chartData = {
            labels: transactions.map((t) => t.date),
            datasets: [
                {
                    label: 'Transaction Amounts',
                    data: transactions.map((t) => t.amount),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 2,
                },
            ],
        };

        const filteredTransactions = transactions.filter(transaction => 
            transaction.type.toLowerCase().includes(searchQuery.toLowerCase())
        );

    // Slider settings for carousel
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    return (
        <div className="wallet">
            {/* Wallet Balance Display */}
            <div className="wallets">
            <h2>Wallet Management</h2>
            </div>
            <motion.div className="wallet-section" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                {walletId && <p><strong>Wallet ID:</strong> {walletId}</p>}
                {walletBalance !== null && <p><strong>Wallet Balance:</strong> {walletBalance} ₦</p>}
                {/* Plus icon for creating a new wallet */}
                <FaPlusCircle 
                    className="create-wallet-icon" 
                    onClick={handleNavigateToCreateWallet} 
                    title="Create Wallet" 
                />
            </motion.div>
  
            
            {/* Reward Points Display */}
            <div className="reward-points-section">
                <p>Reward Points: {rewardPoints}</p>
            </div>

            {/* Transaction Forms */}
            <motion.div className="wallet-section" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                <TopUpForm onTopUp={handleTopUp} />
            </motion.div>
            <motion.div className="wallet-section" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                <WithdrawalForm onWithdraw={handleWithdraw} />
            </motion.div>
            <motion.div className="wallet-section" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                <TransferForm onTransfer={handleTransfer} />
            </motion.div>

            {/* Search Transactions */}
            <div className="wallet-section search-bar">
                <input 
                    type="text" 
                    placeholder="Search transactions..." 
                    value={searchQuery} 
                    onChange={(e) => setSearchQuery(e.target.value)} 
                />
            </div>

            {/* Transaction History Carousel */}
            <div className="wallet-section transaction-history">
                <h2>Transaction History</h2>
                <Slider {...sliderSettings}>
                    {filteredTransactions.length === 0 ? (
                        <div>No Transactions Found</div>
                    ) : (
                        filteredTransactions.map((transaction, index) => (
                            <div key={index} className="transaction-card">
                                <p><strong>{transaction.type}</strong></p>
                                <p>Amount: ${transaction.amount}</p>
                                <p>Date: {transaction.date}</p>
                            </div>
                        ))
                    )}
                </Slider>
            </div>

            {/* Transaction Summary Chart */}
            <div className="wallet-section chart-section">
                <h2>Transaction Summary</h2>
                <Bar data={chartData} options={{ responsive: true, maintainAspectRatio: false }} />
            </div>

            {/* Secure Transaction Badge */}
            <div className="secure-badge">
                <img src={secureIcon} alt="Secure" />
                <p>Your transactions are secure!</p>
            </div>
        </div>
    );
};

export default Wallet;
