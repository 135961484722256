
import React, { useState } from 'react';

const NewWithdrawForm = ({ onWithdraw }) => {
    const [amount, setAmount] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (amount) {
            onWithdraw(amount);
            setAmount('');
        } else {
            alert('Please enter an amount to withdraw.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Withdraw from Wallet</h2>
            <input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
            <button type="submit">Withdraw</button>
        </form>
    );
};

export default NewWithdrawForm;
