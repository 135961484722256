import "./CSS/TransferForm.css";
import React, { useState } from 'react';

const TransferForm = ({ onTransfer }) => {
    const [amount, setAmount] = useState('');
    const [recipient, setRecipient] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (amount && recipient) {
            onTransfer(recipient, amount);
            setAmount('');
            setRecipient('');
        } else {
            alert('Please enter both recipient and amount.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Transfer Funds</h2>
            <input
                type="text"
                placeholder="Recipient Username"
                value={recipient}
                onChange={(e) => setRecipient(e.target.value)}
            />
            <input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
            <button type="submit">Transfer</button>
        </form>
    );
};

export default TransferForm;
