import React, { useContext } from 'react';
import { WishlistContext } from '../Context/WishlistContext';
import "./CSS/Wishlist.css";


const Wishlist = () => {
  const { wishlistItems, removeFromWishlist } = useContext(WishlistContext);

  return (
    <div className="wishlist-page">
      {/* Side menu */}
      <div className="side-menu">
        <ul>
          <li>My Account</li>
          <li>Orders</li>
          <li>Pending Reviews</li>
          <li>Voucher</li>
          <li className="active">Saved Items</li>
          <li>Recently Viewed</li>
          <li>Account Management</li>
        </ul>
      </div>

      {/* Wishlist container */}
      <div className="wishlist-container">
        <h2>Saved Items</h2>
        {wishlistItems.length === 0 ? (
          <p className="wishlist-empty">Your wishlist is empty.</p>
        ) : (
          <div>
            <ul className="wishlist-items">
              {wishlistItems.map((item) => (
                <li key={item.id} className="wishlist-item">
                  <img 
                    className="wishlist-item-image" 
                    src={item.image || "https://via.placeholder.com/80"} 
                    alt={item.name} 
                  />
                  <div className="wishlist-item-details">
                    <span className="wishlist-item-name">{item.name}</span>
                    <span className="wishlist-item-price">₦{item.price}</span>
                    {item.discount && (
                      <span className="wishlist-item-discount">- {item.discount}%</span>
                    )}
                    <span className="wishlist-item-stock">
                      {item.inStock ? 'In Stock' : 'Out of Stock'}
                    </span>
                  </div>
                  <div className="wishlist-item-actions">
                    <button className="buy-now-btn">BUY NOW</button>
                    <button className="remove-btn" onClick={() => removeFromWishlist(item.id)}>REMOVE</button>
                  </div>
                </li>
              ))}
            </ul>

            {/* Pagination */}
            <div className="pagination">
              <button>&laquo;</button>
              <button className="active">1</button>
              <button>2</button>
              <button>3</button>
              <button>&raquo;</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Wishlist;