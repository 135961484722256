import React from 'react';
import { useNavigate } from 'react-router-dom';
import CartItemsList from '../Components/CartItems/CartItems';


const Cart = () => {
  const navigate = useNavigate();

  return (
    <div className="cart-container">
      <CartItemsList />
    
    </div>
  );
};

export default Cart;
