import React, { useState, useEffect } from "react";
import contactImage from "../Components/Assets/contact_img.png";
import Skeleton from "react-loading-skeleton"; // Import Skeleton loader
import "react-loading-skeleton/dist/skeleton.css"; // Skeleton styles

const Contact = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate image loading delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay for the loader
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      {/* Skeleton Loader for the entire page */}
      {loading ? (
        <Skeleton height={600} width="100%" />
      ) : (
        <>
          {/* Banner Section */}
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <img
              src={contactImage}
              alt="Contact Us"
              style={{
                width: "100%",
                maxHeight: "400px", // Adjust the height as needed
                objectFit: "cover", // Ensures the image covers the area while maintaining aspect ratio
                borderRadius: "8px",
              }}
            />
          </div>

          <header style={styles.header}>
            <h1 style={styles.headerText}>Get in Touch</h1>
            <p style={styles.subHeaderText}>
              We'd love to hear from you! Fill out the form below or reach us at
              our contact details.
            </p>
          </header>

          <div style={styles.formContainer}>
            <form style={styles.form}>
              <label style={styles.label}>Your Name</label>
              <input type="text" style={styles.input} required />

              <label style={styles.label}>Email Address</label>
              <input type="email" style={styles.input} required />

              <label style={styles.label}>Message</label>
              <textarea style={styles.textarea} rows="5" required />

              <button type="submit" style={styles.button}>
                Send Message
              </button>
            </form>
          </div>

          {/* Spacing between the form and footer */}
          <div style={{ margin: "40px 0" }} />

          <footer style={styles.footer}>
            <h2 style={styles.footerHeader}>Contact Details</h2>
            <p style={styles.footerText}>📞 Phone: +123 456 7890</p>
            <p style={styles.footerText}>✉️ Email: info@mbokconnect.com</p>
            <p style={styles.footerText}>🏢 Address: 123 E-commerce St, Lagos, Nigeria</p>
          </footer>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "90px 40px",
    backgroundColor: "#f8f8f8",
    borderRadius: "10px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    margin: "40px auto",
    fontFamily: "'Arial', sans-serif",
  },
  header: {
    textAlign: "center",
    marginBottom: "30px",
  },
  headerText: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#333",
  },
  subHeaderText: {
    fontSize: "1.2rem",
    color: "#666",
    marginTop: "10px",
  },
  formContainer: {
    backgroundColor: "#fff",
    padding: "30px",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#333",
  },
  input: {
    padding: "12px",
    marginBottom: "15px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "1rem",
  },
  textarea: {
    padding: "12px",
    marginBottom: "15px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "1rem",
  },
  button: {
    padding: "12px",
    backgroundColor: "#ff6f61",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    fontSize: "1rem",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  footer: {
    textAlign: "center",
    marginTop: "40px",
  },
  footerHeader: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#333",
  },
  footerText: {
    color: "#666",
    margin: "5px 0",
  },
};

export default Contact;
