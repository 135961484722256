import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Shop from "./Pages/Shop";
import Cart from "./Pages/Cart";
import Product from "./Pages/Product";
import SearchBar from "./Components/SearchBar/SearchBar";
import LoginSignup from "./Pages/LoginSignup";
import PlaceOrder from "./Pages/PlaceOrder";

// Utility Services Components
import UtilityServices from './Pages/UtilityServices';
import AirtimeRecharge from './Pages/AirtimeRecharge';
import DataSubscription from './Pages/DataSubscription';
import InternetSubscription from './Pages/InternetSubscription';
import CableSubscription from './Pages/CableSubscription';
import ElectricityBillPayment from './Pages/ElectricityBillPayment';
import Contact from "./Pages/Contact";
import Mens from "./Pages/Mens";
import About from "./Pages/About";

import { getAppUrls } from './config';
import { PublicRoutes, ProtectedRoutes } from "./Components/AuthGuard";
import Footer from "./Components/Footer/Footer";
import Orders from "./Pages/Orders";
import Profile from "./Pages/Profile";
import Wallet from "./Pages/Wallet";
import Invoice from "./Pages/Invoice";
import Notification from "./Components/Notification/Notification";
import ProductSearchPage from "./Components/SearchBar/ProductSearchPage";

import { WishlistProvider } from "./Context/WishlistContext";
import Wishlist from "./Pages/Wishlist";
import JivoChat from "./JivoChat";
import { ThemeProvider } from "./Context/ThemeContext";
import AutoLogout from "./Pages/AutoLogout";
import './global.css'; 

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Success from "./Components/Form/Success";
import CODCheckout from "./Components/Checkout/CODCheckout";
import StripeCheckout from "./Components/Checkout/StripeCheckout";
import PaystackCheckout from "./Components/Checkout/PaystackCheckout";
import StripeComplete from "./Components/OrderComplete/StripeComplete";
import CreateWallet from "./Components/Wallet/CreateWallet";

const stripePromise = loadStripe('pk_test_51QCbYSAD2kinhv4AcA2Fw1zgIjOTICOahTJlZELDs2bFGBSrvAqIzO5f9lAn2UiD9OyKDCmiMT1X3PxiqJRKVszv00mWwl0sC7'); 
export const backend_url = getAppUrls().url;
export const currency = process.env.REACT_APP_CURRENCY;

function App() {
  return (
    <WishlistProvider> 
       <ThemeProvider>
      <Router>
      <AutoLogout /> 
        <Navbar />
        <SearchBar />
        <JivoChat />
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<PublicRoutes />}>
            <Route path="/" element={<Shop gender="all" />} />
            <Route path='/mens' element={<Mens />} />
            <Route path='/product' element={<Product />}>
            <Route path=':productId' element={<Product />} />
          </Route>
          <Route path='/place-order' element={<PlaceOrder />} />
    {/* Wrap the checkout routes with <Elements> */}
    <Route path='/stripe-checkout' element={
                <Elements stripe={stripePromise}>
                  <StripeCheckout />
                </Elements>
              } />
           <Route path='/success' element={<Success />} />
           <Route path="/complete" element={< StripeComplete/>} />
           <Route path='/cod-checkout' element={<CODCheckout />} />
           <Route path='/stripe-checkout' element={<StripeCheckout />} />
           <Route path='/paystack-checkout' element={<PaystackCheckout />} />
            <Route path="/login" element={<LoginSignup />} />
            <Route path="/utility-services" element={<UtilityServices />} />
            <Route path="/airtime-recharge" element={<AirtimeRecharge />} />
            <Route path="/data-subscription" element={<DataSubscription />} />
            <Route path="/internet-subscription" element={<InternetSubscription />} />
            <Route path="/cable-subscription" element={<CableSubscription />} />
            <Route path="/electricity-bill-payment" element={<ElectricityBillPayment />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/myorders" element={<Orders />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/wallet" element={<Wallet/>} />
            <Route path="/create-wallet" element={<CreateWallet />} />
          
            <Route path="/invoice" element={<Invoice/>} />
            <Route path="/notification" element={<Notification/>} />
         
            <Route path="/product-search" element={<ProductSearchPage />} /> 
            <Route path="/wishlist" element={<Wishlist/>} />
          

            {/* Fallback Route */}
            <Route path="*" element={<h2>404 Not Found</h2>} />
          </Route>

          {/* Protected Routes */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/cart" element={<Cart />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
      </ThemeProvider>
      </WishlistProvider>
  );
}

export default App;
