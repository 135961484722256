import React, { useState } from 'react';
import './CSS/InternetSubscription.css';
import internetImage from '../Components/Assets/Nigerian-Networks-Double-Data-1-2366958140.jpg';

const InternetSubscription = () => {
  const [serviceProvider, setServiceProvider] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [amount, setAmount] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to subscribe to an internet service
    alert(`Subscribing customer ${customerId} to ${serviceProvider} with ₦${amount}`);
  };

  return (

    <div className="airtime-page">
    {/* Banner Image */}
    <div className="banner-container">
     <img src={internetImage} alt="Banner" className="internet-image" />
   </div>
   

    <div className="internet-subscription">
      <h2>Internet Subscription</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="service-provider">Service Provider:</label>
          <select
            id="service-provider"
            value={serviceProvider}
            onChange={(e) => setServiceProvider(e.target.value)}
            required
          >
            <option value="">Select Provider</option>
            <option value="Spectranet">Spectranet</option>
            <option value="Smile">Smile</option>
            <option value="Swift">Swift</option>
            <option value="NTel">NTel</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="customer-id">Customer ID:</label>
          <input
            type="text"
            id="customer-id"
            value={customerId}
            onChange={(e) => setCustomerId(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="amount">Amount (₦):</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <button type="submit">Subscribe to Internet</button>
      </form>
    </div>
    </div>
  );
};

export default InternetSubscription;
