// src/Pages/DataSubscription.jsx

import React, { useState } from "react";
import "./CSS/DataSubscription.css";
import dataImage from "../Components/Assets/glo-etisalat-nd-mtn-logo-65021854.gif";
import PaymentConfirmation from "../Components/PaymentConfirmation/PaymentConfirmation";

const DataSubscription = () => {
    const [serviceType, setServiceType] = useState("mobile"); // Track active subscription type
    const [provider, setProvider] = useState("");
    const [accountInfo, setAccountInfo] = useState("");
    const [plan, setPlan] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        const details = {
            provider,
            accountInfo,
            plan,
        };
        setTransactionDetails(details);
        setShowConfirmation(true);
    };

    const handleConfirmation = async (result) => {
        alert(`Data subscription successful: ${result.response_description}`);
        // Reset form
        setProvider("");
        setAccountInfo("");
        setPlan("");
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

    const renderForm = () => (
        <>
            <div className="form-group">
                <label htmlFor="provider">Select Provider:</label>
                <select
                    id="provider"
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                    required
                >
                    <option value="">Select Provider</option>
                    {serviceType === "mobile" ? (
                        <>
                            <option value="mtn">MTN</option>
                            <option value="glo">Glo</option>
                            <option value="airtel">Airtel</option>
                            <option value="9mobile">9Mobile</option>
                        </>
                    ) : (
                        <>
                            <option value="smile">Smile</option>
                            <option value="spectranet">Spectranet</option>
                        </>
                    )}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="account-info">
                    {serviceType === "mobile" ? "Phone Number:" : "Account Number:"}
                </label>
                <input
                    type="text"
                    id="account-info"
                    value={accountInfo}
                    onChange={(e) => setAccountInfo(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label htmlFor="plan">Select Plan:</label>
                <select
                    id="plan"
                    value={plan}
                    onChange={(e) => setPlan(e.target.value)}
                    required
                >
                    <option value="">Select Plan</option>
                    {serviceType === "mobile" ? (
                        <>
                            <option value="500MB">500MB - ₦100</option>
                            <option value="1GB">1GB - ₦200</option>
                            <option value="5GB">5GB - ₦1000</option>
                        </>
                    ) : (
                        <>
                            <option value="10GB">10GB - ₦3000</option>
                            <option value="25GB">25GB - ₦6000</option>
                            <option value="unlimited">Unlimited - ₦10000</option>
                        </>
                    )}
                </select>
            </div>
            <button type="submit" className="subscribe-button">Subscribe</button>
        </>
    );

    return (
        <div className="data-page">
            <div className="banner-container">
                <img src={dataImage} alt="Banner" className="data-image" />
            </div>
            <div className="data-subscription">
                <h2 className="data-header">Data Subscription</h2>
                <div className="service-toggle">
                    <button
                        className={`toggle-button ${serviceType === "mobile" ? "active" : ""}`}
                        onClick={() => setServiceType("mobile")}
                    >
                        Mobile Data
                    </button>
                    <button
                        className={`toggle-button ${serviceType === "office" ? "active" : ""}`}
                        onClick={() => setServiceType("office")}
                    >
                        Internet
                    </button>
                </div>
                {showConfirmation ? (
                    <PaymentConfirmation
                        transactionDetails={transactionDetails}
                        onConfirm={handleConfirmation}
                        onCancel={handleCancel}
                    />
                ) : (
                    <form onSubmit={handleSubmit} className="data-form">
                        {renderForm()}
                    </form>
                )}
            </div>
        </div>
    );
};

export default DataSubscription;
