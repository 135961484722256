import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./Item.css";
import { Link } from "react-router-dom";
import { backend_url, currency } from "../../App";

const Item = (props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data loading
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the time based on your data loading time

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="item">
      {loading ? (
        <>
          <Skeleton height={200} width={200} />
          <Skeleton width={`80%`} />
          <Skeleton width={`50%`} />
        </>
      ) : (
        <>
          <Link to={`/product/${props.id}`}>
            <img
              onClick={() => window.scrollTo(0, 0)}
              src={`${backend_url}${props.image}`}
              alt="products"
              crossOrigin="anonymous"
            />
          </Link>
          <p>{props.name}</p>
          <div className="item-prices">
            <div className="item-price-new">
              {currency}
              {props.new_price}
            </div>
            {/* Conditionally render old price only if it's different and exists */}
            {props.old_price && props.old_price !== props.new_price && (
              <div className="item-price-old">
                {currency}
                {props.old_price}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Item;
