import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import "./CODCheckout.css";

const CODCheckout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { formData, totalAmount } = location.state;

      // Function to generate a random Order ID (just for example purposes)
      const generateOrderId = () => {
        return 'ORD-' + Math.floor(100000 + Math.random() * 900000); // Random 6-digit order ID
    };

    const handlePlaceOrder = () => {
         // Generate a random order ID
         const orderId = generateOrderId();

        // Logic for handling COD order placement
        console.log("Order placed via COD:", formData, totalAmount, orderId);

          // Navigate to the success page with the order details
          navigate('/success', { state: { formData, totalAmount, orderId, paymentMethod: "Cash on Delivery" } });

    };

    return (
        <div className="cod-checkout-container">
            <h2>Cash on Delivery Checkout</h2>
            <p className="order-summary">Total Amount: ₦{totalAmount.toLocaleString()}</p>
            <div className="order-details">
                {/* Display formData for confirmation */}
                <p><span>Name:</span> {formData.firstName} {formData.lastName}</p>
                <p><span>Address:</span> {formData.street}, {formData.city}, {formData.state}, {formData.zipcode}, {formData.country}</p>
                <p><span>Phone:</span> {formData.phone}</p>
            </div>
            <button className="place-order-button" onClick={handlePlaceOrder}>Place Order</button>
            <p className="footer-text">By placing this order, you agree to our <a href="/terms">Terms & Conditions</a>.</p>
        </div>
    );
};

export default CODCheckout;
