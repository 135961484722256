import React, { useState } from 'react';
import axios from 'axios';
import './TwoFactorVerification.css'; // Import your CSS file

const TwoFactorVerification = () => {
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const token = localStorage.getItem('auth-token'); // Assuming you saved it earlier

  const verifyCode = async () => {
    try {
      const response = await axios.post(
        `/api/2fa-verify`,
        { code },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.status) {
        alert('2FA verification successful!');
        window.location.replace('/'); // Redirect to the homepage
      } else {
        setErrorMessage(response.data.message || 'Verification failed.');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setErrorMessage('An error occurred during verification.');
    }
  };

  return (
    <div className="two-fa-verification">
      <input
        type="text"
        placeholder="Enter your verification code"
        value={code}
        onChange={(e) => setCode(e.target.value)}
        className="verification-input"
      />
      <button onClick={verifyCode} className="verify-btn">Verify</button>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default TwoFactorVerification;
