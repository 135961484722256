import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Success.css'; 

const Success = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { formData, totalAmount, orderId, paymentMethod, cartItems } = location.state || {}; 
    
    const handleGoHome = () => {
        navigate('/');
    };

    // Save order to the backend
    useEffect(() => {
        const saveOrder = async () => {
            const apiUrl = process.env.REACT_APP_ENVIRONMENT === 'production'
                ? process.env.REACT_APP_PRODUCTION_BaseApi_URL
                : process.env.REACT_APP_STAGING_BaseApi_URL;

            try {
                const response = await fetch(`${apiUrl}/orders`, {
                    method: 'POST',
                    headers: {
                        'auth-token': localStorage.getItem("auth-token"), // Assuming auth-token is stored in local storage
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        totalAmount, // Send the total amount
                        currency: 'NGN', // Or whatever your currency is
                        items: cartItems, // Send the cart items
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        email: formData.email,
                        shippingAddress: {
                            street: formData.street,
                            city: formData.city,
                            state: formData.state,
                            zipcode: formData.zipcode,
                            country: formData.country,
                        },
                        paymentStatus: paymentMethod === 'Cash on Delivery' ? 'Pending' : 'Paid', // Set based on payment method
                        orderId, // Include the order ID
                    }),
                });

                if (!response.ok) throw new Error('Failed to save the order');

                const orderData = await response.json(); // Store the response data if needed
                console.log("Order saved successfully", orderData);
            } catch (error) {
                console.error("Error saving the order:", error);
            }
        };

        if (formData && orderId) {
            saveOrder(); // Save order when the component mounts
        }
    }, [formData, orderId, totalAmount, paymentMethod, cartItems]);

    return (
        <div className="success-container">
            <h2>Order Placed Successfully!</h2>

            {formData ? (
                <div className="invoice-section">
                    <h3>Invoice Details</h3>
                    <p><strong>Order ID:</strong> {orderId}</p>
                    <p><strong>Name:</strong> {formData.firstName} {formData.lastName}</p>
                    <p><strong>Email:</strong> {formData.email}</p>
                    <p><strong>Shipping Address:</strong> {formData.street}, {formData.city}, {formData.state}, {formData.zipcode}, {formData.country}</p>
                    <p><strong>Payment Method:</strong> {paymentMethod}</p>
                    <p><strong>Total Amount:</strong> ₦{totalAmount?.toLocaleString()}</p>
                </div>
            ) : (
                <p>Order details are not available. Please try again later.</p>
            )}

            <button onClick={handleGoHome}>Return to Homepage</button>
        </div>
    );
};

export default Success;
