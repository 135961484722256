import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";

const useAuth = () => {
  // Get the user item from localStorage
  let user;
 const token = localStorage.getItem("auth-token");

  if (token) {
    try {
      // Decode the JWT token
      const decodedToken = jwtDecode(token);
      console.log(decodedToken);

      user = {
        ...decodedToken,
        auth: true,
      };
    } catch (error) {
      console.error("Invalid token", error);
    }
  }

  if (user) {
    return user;
  } else {
    return {
      auth: false,
      role: null,
    };
  }
};

const ProtectedRoutes = () => {
  const { auth, role } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate("/login"); // Redirect to login if not authenticated
    } else if (role) {
      const roles = role;//role.split(",");
        console.log(roles);
      // If user doesn't have the required roles, redirect to permission denied page
      if (
        !roles.includes("guest") &&
        !roles.includes("customer") &&
        !roles.includes("HRM") &&
        !roles.includes("fincon")
      ) {
        navigate("/denied");
      }
    }
  }, [auth, role, navigate]);

  // Render the protected routes only if authenticated and role matches
  if (auth && role) {
    const roles = role;
    console.log(roles);
    if (
      roles.includes("guest") ||
      roles.includes("customer") ||
      roles.includes("HRM") ||
      roles.includes("fincon")
    ) {
      return <Outlet />;
    }
  }

  return null; // Don't render anything if navigating or unauthenticated
};

export default ProtectedRoutes;