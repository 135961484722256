import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TwoFASettings.css'; // Import your CSS file

const TwoFASettings = ({ onChange }) => {
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    const fetchUserSettings = async () => {
      try {
        const response = await axios.get('/api/user-settings');
        setIs2FAEnabled(response.data.is2FAEnabled);
      } catch (error) {
        console.error('Error fetching user settings:', error);
      }
    };

    fetchUserSettings();
  }, []);

  const toggle2FA = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/toggle-2fa', { enable: !is2FAEnabled });
      const newStatus = response.data.success;
      setIs2FAEnabled(newStatus);
      onChange(newStatus); // Notify parent of 2FA status change
      if (newStatus) {
        const qrResponse = await axios.post('/api/generate-2fa-qr');
        setQrCodeUrl(qrResponse.data.qrCodeUrl);
      } else {
        setQrCodeUrl(''); // Clear QR code if disabling 2FA
      }
    } catch (error) {
      console.error('Error toggling 2FA:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleConfirmation = () => {
    setShowConfirmation(false);
    toggle2FA(); // Call toggle after confirmation
  };

  return (
    <div className="two-fa-settings">
      <button className={`toggle-btn ${is2FAEnabled ? 'enabled' : 'disabled'}`} onClick={() => setShowConfirmation(true)} disabled={loading}>
        {loading ? 'Processing...' : (is2FAEnabled ? 'Disable 2FA' : 'Enable 2FA')}
      </button>
      
      {showConfirmation && (
        <div className="confirmation-modal">
          <p>Are you sure you want to {is2FAEnabled ? 'disable' : 'enable'} 2FA?</p>
          <button onClick={handleToggleConfirmation}>Yes</button>
          <button onClick={() => setShowConfirmation(false)}>No</button>
        </div>
      )}

      {is2FAEnabled && qrCodeUrl && (
        <div className="qr-code-section">
          <h3>Scan the QR Code</h3>
          <img src={qrCodeUrl} alt="2FA QR Code" />
        </div>
      )}
    </div>
  );
};

export default TwoFASettings;
