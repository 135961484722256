// src/components/SearchBar.jsx
import React, { useContext, useEffect, useState } from 'react';
import { ShopContext } from '../../Context/ShopContextProvider'; // Ensure this path matches your project
import { assets } from '../Assets/assets'; // Path to your assets folder
import { useLocation } from 'react-router-dom';

const SearchBar = ({ products }) => {
  const { search, setSearch, showSearch, setShowSearch } = useContext(ShopContext);
  const [visible, setVisible] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('collection')) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [location]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);

    if (value.length > 2) {
      const filteredSuggestions = products.filter((product) =>
        product.name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  return showSearch && visible ? (
    <div className="border-t border-b bg-gray-50 text-center">
      <div className="inline-flex items-center justify-center border border-gray-400 px-5 py-2 my-5 mx-3 rounded-full w-3/4 sm:w-1/2">
        <input
          value={search}
          onChange={handleSearchChange}
          className="flex-1 outline-none bg-inherit text-sm"
          type="text"
          placeholder="Search for products..."
        />
        <img className="w-4" src={assets.search_icon} alt="Search Icon" />
      </div>
      <img
        onClick={() => setShowSearch(false)}
        className="inline w-3 cursor-pointer"
        src={assets.cross_icon}
        alt="Close Icon"
      />

      {suggestions.length > 0 && (
        <ul className="suggestions-list bg-white shadow-lg rounded-lg mt-2 mx-auto w-3/4 sm:w-1/2 text-left">
          {suggestions.map((product, index) => (
            <li key={index} className="p-2 border-b last:border-none hover:bg-gray-100">
              {product.name} - {product.price} - {product.category}
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null;
};

export default SearchBar;
