import React, { useEffect, useState } from 'react';
import './RelatedProducts.css';
import Item from '../Item/Item';
import { backend_url } from '../../App';

const RelatedProducts = ({ category, id }) => {
  const [related, setRelated] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true); // Start loading
    fetch(`${backend_url}/relatedproducts`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ category: category }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then((data) => {
        setRelated(data);
        setLoading(false); // Stop loading
      })
      .catch((err) => {
        console.error('Error fetching related products:', err);
        setError(err.message); // Set error message
        setLoading(false); // Stop loading
      });

    // Cleanup function to avoid memory leaks
    return () => {
      setRelated([]); // Optional: Reset related products
      setLoading(false); // Optional: Reset loading state
      setError(null); // Optional: Reset error state
    };
  }, [category, id]); // Dependency array to refetch if category or id changes

  return (
    <div className='relatedproducts'>
      <h1>Related Products</h1>
      <hr />
      {loading && <p>Loading...</p>} {/* Loading state */}
      {error && <p>Error: {error}</p>} {/* Error state */}
      <div className="relatedproducts-item">
        {related.map((item, index) => {
          if (id !== item.id) {
            return (
              <Item
                key={index}
                id={item.id}
                name={item.name}
                image={item.image}
                new_price={item.new_price}
                old_price={item.old_price}
              />
            );
          }
          return null; // Ensure to return null if the condition fails
        })}
      </div>
    </div>
  );
};

export default RelatedProducts;
