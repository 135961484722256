import React  from 'react';
import './Footer.css';
import { useTheme } from '../../Context/ThemeContext';

import footer_logo from '../Assets/logo_big.png';
import instagram_icon from '../Assets/instagram_icon.png';
import pintrest_icon from '../Assets/pintester_icon.png';
import whatsapp_icon from '../Assets/whatsapp_icon.png';

const Footer = () => {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <div className='footer'>
      {/* Footer Container */}
      <div className='footer-container'>

        {/* Footer Logo */}
        <div className='footer-logo'>
          <img src={footer_logo} alt="MBOK Connect Logo" />
          <p>MBOK CONNECT</p>
        </div>

        {/* Footer Links */}
        <ul className='footer-links'>
          <li>Company</li>
          <li>Home</li>
          <li>Delivery</li>
          <li>About Us</li>
          <li>Privacy Policy</li>
        </ul>

        {/* Get In Touch and Social Icons Section */}
        <div className='footer-content'>
          {/* Left: Get In Touch */}
          <div className='footer-get-in-touch'>
            <p className='text-xxl font-bold text-black mb-5'>GET IN TOUCH</p>
            <ul className='flex flex-col gap-1'>
              <li className='text-sm'>+1-212-456-7890</li>
              <li className='text-sm'>info@mbokconnect.com</li>
            </ul>
          </div>

          {/* Right: Social Icons */}
          <div className='footer-social-icons'>
            <img src={instagram_icon} alt='Instagram' className='social-icon' />
            <img src={pintrest_icon} alt='Pinterest' className='social-icon' />
            <img src={whatsapp_icon} alt='WhatsApp' className='social-icon' />
          </div>
        </div>
    {/* Dark Mode Toggle - Positioned to the left */}
    <div className="dark-mode-toggle" style={{ position: 'fixed', bottom: '20px', left: '20px' }}>
          <label className="switch">
            <input type="checkbox" checked={isDarkMode} onChange={toggleTheme} />
            <span className="slider"></span>
          </label>
        </div>

      </div>

      {/* Footer Copyright */}
      <div className='footer-copyright'>
        <hr />
        <p className='py-5 text-sm text-center'>Copyright 2023 @ mbokconnect.com - All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
