import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import "./StripeComplete.css";

const StripeComplete = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [sessionDetails, setSessionDetails] = useState(null);

  useEffect(() => {
    const fetchSessionDetails = async () => {
      const query = new URLSearchParams(location.search);
      const session_id = query.get("session_id");

      try {
        // Fetch session details from backend
        const response = await axios.get(`http://localhost:4000/retrieve-session?session_id=${session_id}`);
        if (response.data.status === "success") {
          setSessionDetails(response.data);

          // Save order details to the database
          await saveOrderToDatabase(response.data);
          toast.success("Order completed successfully!");
        } else {
          toast.error("Payment not successful. Please try again.");
          navigate("/"); // Redirect if payment was not successful
        }
      } catch (error) {
        console.error("Error fetching session details:", error);
        toast.error("There was an error completing your order.");
      }
    };

    fetchSessionDetails();
  }, [location.search, navigate]);

  const saveOrderToDatabase = async (sessionData) => {
    try {
      const orderData = {
        sessionId: sessionData.sessionId,
        customerEmail: sessionData.customerEmail,
        amountTotal: sessionData.amountTotal / 100, // Convert from cents to actual value
        currency: sessionData.currency,
      };

      // Send order data to backend for saving
      await axios.post("http://localhost:4000/save-order", orderData);
      toast.success("Order details saved to your account.");
    } catch (error) {
      console.error("Error saving order:", error);
      toast.error("Failed to save order details. Please contact support.");
    }
  };

  return (
    <div className="order-complete">
      {sessionDetails ? (
        <div className="order-details">
          <h2>Thank You for Your Purchase! <br /> Order Completed! </h2>
          <p>Your order has been successfully placed. <br /> Thank you for shopping with us! </p>
          <p>Order ID: {sessionDetails.sessionId}</p>
          <p>Amount Paid: {(sessionDetails.amountTotal / 100).toFixed(2)} {sessionDetails.currency.toUpperCase()}</p>
          <p>An email has been sent to: {sessionDetails.customerEmail}</p>
          <button onClick={() => navigate("/orders")}>View Your Orders</button>
          <button onClick={() => navigate("/mens")}>Continue Shopping</button>
        </div>
      ) : (
        <p>Loading order details...</p>
      )}
    </div>
  );
};

export default StripeComplete;
