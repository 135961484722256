import React, { useContext } from "react";
import { FiTrash2, FiHeart} from "react-icons/fi"; // Import arrow icon
import "./CartItems.css";
import { ShopContext } from "../../Context/ShopContextProvider";
import { backend_url, currency } from "../../App";
import { Link, useNavigate } from "react-router-dom";

const CartItems = () => {
  const navigate = useNavigate();
  const {
    products,
    cartItems,
    removeFromCart,
    getTotalCartAmount,
    updateQuantity,
    handleAddToWishlist,
  } = useContext(ShopContext);

  const cartSubtotal = getTotalCartAmount();

  return (

    <>
      <div className="d-flex flex-row align-items-center mb-3">
             
              <Link to="/mens" className="ml-2 text-decoration-none text-dark">  <span className="back-arrow" style={{ fontSize: "1.2rem", marginRight: "5px" }}>
    &#8592; {/* Unicode for left arrow */}
  </span> Continue Shopping</Link>
            </div>

    <div className="container mt-5 p-3 rounded cart">
      <div className="row">
        <div className="col-md-8">
          <div className="product-details">
            <hr />

            <div className="card">
              <div className="table-responsive">
                <table className="table table-borderless table-shopping-cart">
                  <thead className="text-muted">
                    <tr className="small text-uppercase">
                      <th scope="col">Product</th>
                      <th scope="col" width="120">
                        Quantity
                      </th>
                      <th scope="col" width="120">
                        Price
                      </th>
                      <th
                        scope="col"
                        className="text-right d-none d-md-block"
                        width="200"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product) => {
                      if (cartItems[product.id] > 0) {
                        return (
                          <tr
                            key={product.id}
                            className="d-flex justify-content-between align-items-center mt-3 p-2 items rounded cart-item"
                          >
                            <td>
                              <figure className="itemside align-items-center">
                                <div className="aside">
                                  <img
                                    src={`${backend_url}${product.image}`}
                                    className="img-sm rounded"
                                    alt={product.name}
                                  />
                                </div>
                                <figcaption className="info">
                                  <button
                                    className="title text-dark font-weight-bold"
                                    onClick={() =>
                                      navigate(`/products/${product.id}`)
                                    }
                                  >
                                    {product.name}
                                  </button>
                                  <p className="text-muted small">
                                    Brand: {product.brand}
                                  </p>
                                </figcaption>
                              </figure>
                            </td>
                            <td>
                              <select
                                className="form-control"
                                value={cartItems[product.id]}
                                onChange={(event) =>
                                  updateQuantity(
                                    product.id,
                                    Number(event.target.value)
                                  )
                                }
                              >
                                {[1, 2, 3, 4].map((num) => (
                                  <option key={num} value={num}>
                                    {num}
                                  </option>
                                ))}
                              </select>
                            </td>
                            <td>
                              <div className="price-wrap">
                                <var className="price">
                                  {currency}
                                  {product.new_price}
                                </var>
                              </div>
                            </td>
                            <td className="text-right d-none d-md-block">
                              <button
                                onClick={() => handleAddToWishlist(product.id)}
                                className="btn btn-light wishlist-btn mr-2"
                                aria-label="Add to Wishlist"
                              >
                                <FiHeart className="text-warning" size={20} />
                              </button>
                              <button
                                onClick={() => removeFromCart(product.id)}
                                className="btn btn-light remove-btn"
                                aria-label="Remove from Cart"
                              >
                                <FiTrash2 className="text-danger" size={20} />
                              </button>
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <aside className="summary col-md-4">
          <div className="summary-card">
            <div className="card-body">
              <h2 className="summary-title">Cart Summary</h2>
              <dl className="dlist-align summary-list">
                <dt>Subtotal:</dt>
                <dd className="text-right">
                  {currency}
                  {cartSubtotal}
                </dd>
              </dl>
              <hr />
              <div className="button-group">
                <button
                  className="btn checkout"
                  onClick={() => navigate("/place-order")}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </div>
    </>
  );
};

export default CartItems;
