import React, { useEffect, useState } from "react";
import '../../src/Pages/CSS/Profile.css';
import { Link } from 'react-router-dom';
import TwoFASettings from "../Components/Verification/TwoFASettings";
import TwoFactorVerification from "../Components/Verification/TwoFactorVerification";

const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address: '',
    phone: ''
  });
  const [is2FAVerificationVisible, setIs2FAVerificationVisible] = useState(false);

  const apiBaseURL = "http://localhost:4000"; 

  useEffect(() => {
    const fetchUserProfile = async () => {
      const token = localStorage.getItem('token'); 

      if (!token) {
        // Redirect to login if no token is found
        window.location.href = '/login'; 
        return;
      }
      setLoading(true);
      try {
        const response = await fetch('http://localhost:4000/user/profile', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,// Assuming token-based authentication
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            window.location.href = '/login';
            return;
        }
        throw new Error('Failed to fetch user info');
      }
      
        const data = await response.json();
        setUserInfo(data);
        setFormData({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          address: data.address || '',
          phone: data.phone || '',
        });
      } catch (error) {
        console.error("Error fetching user info:", error);
        setError("Failed to fetch user info. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [apiBaseURL]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    fetch(`${apiBaseURL}/user/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to update user info');
        }
        return res.json();
      })
      .then((data) => {
        setUserInfo(data);
        setIsEditing(false);
        alert("Profile updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating user info:", error);
        setError("Failed to update user info. Please try again.");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  if (loading) {
    return <p>Loading user information...</p>;
  }

  return (
    <div className="profile-container">
      <div className="sidebar">
        <ul>
          <li><Link to="/profile">My Accounts</Link></li>
          <li><Link to="/orders">My Orders</Link></li>
          <li><Link to="/returns-cancel">Returns & Cancel</Link></li>
          <li><Link to="/my-rating-reviews">My Rating & Reviews</Link></li>
          <li><Link to="/wishlist">My Wishlist</Link></li>
          <li><Link to="/payment">Payment</Link></li>
          <li><Link to="/change-password">Change Password</Link></li>
        </ul>
      </div>

      <div className="main-profile">
        <div className="profile-header">
          <h2>Hello, {userInfo?.firstName || "Guest"}</h2>
          <button onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Cancel' : 'Edit Profile'}
          </button>
        </div>

        {isEditing && (
          <form onSubmit={handleSubmit}>
            <h3>User Information</h3>
            {error && <p className="error">{error}</p>}
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="First Name" required />
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Last Name" required />
            <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email" required />
            <input type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Address" required />
            <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder="Phone Number" required />
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Updating...' : 'Update Profile'}
            </button>
          </form>
        )}

        <div>
          <h3>Two Factor Authentication</h3>
          <button onClick={() => setIs2FAVerificationVisible(!is2FAVerificationVisible)}>
            {is2FAVerificationVisible ? 'Close 2FA Settings' : 'Open 2FA Settings'}
          </button>
          {is2FAVerificationVisible && <TwoFASettings />}
          {is2FAVerificationVisible && <TwoFactorVerification />}
        </div>
      </div>
    </div>
  );
};

export default Profile;
